/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import React, { createContext, useContext, useEffect, useState } from "react";
import { MdOutlineMessage } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { chatActions } from "../app/features/chat/chat.slice";
import {
  conversationActions,
  readMessages,
} from "../app/features/conversations/conversation.slice";
import useAuth from "../hooks/useAuth";
import SocketContext from "./socketContext";

const ChatContext = createContext();

// eslint-disable-next-line react/prop-types
export function ChatProvider({ children }) {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { socket } = useContext(SocketContext);
  const [selectedChat, setSelectedChat] = useState(null);
  const [newMessage, setNewMessage] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (!socket) return;

    // Add a listener for "receiveMessage" event
    const receiveMessageHandler = (data) => {
      console.log("Received message:", data);

      setNewMessage({ ...data, senderName: data.username });
    };

    // Attach the event listener
    socket.on("receiveMessage", receiveMessageHandler);

    // Clean up by removing the event listener when the component unmounts
    // eslint-disable-next-line consistent-return
    return () => {
      socket.off("receiveMessage", receiveMessageHandler);
    };
  }, [socket]);

  useEffect(() => {
    if (!newMessage) return;

    dispatch(chatActions.receiveNewMessage({ newMessage }));

    if (selectedChat?.id !== newMessage.chatId) {
      const toastMessage = (
        <div>
          <h5>{newMessage.senderName}</h5>
          <span>{newMessage.message}</span>
        </div>
      );

      toast(toastMessage, {
        position: "top-right",
        hideProgressBar: false,
        pauseOnHover: true,
        icon: <MdOutlineMessage color="#40BEEE" />,
        type: "success",
        onClick: () => navigate(`/chat/${newMessage.chatId}`),
      });
    }

    if (
      selectedChat?.id === newMessage.chatId &&
      newMessage.sentBy !== user?.id
    ) {
      dispatch(conversationActions.receiveNewMessage({ newMessage }));
    }

    setNewMessage("");

    // New message is on current chat
    if (selectedChat?.id === newMessage.chatId) {
      dispatch(readMessages({ chatId: selectedChat?.id }));
    }
  }, [newMessage, setNewMessage]);

  const sendMessageSocket = ({ message, fileType, file }) => {
    socket?.emit("sendMessage", {
      userId: user?.id,
      userName: user?.fullName,
      chatId: selectedChat?.id,
      roomMembers: selectedChat?.groupMembers,
      originalSender: selectedChat?.sender,
      message,
      fileType,
      file,
    });
  };

  const sendCallSocket = (callingPayload) => {
    socket?.emit("call", callingPayload);
  };

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <ChatContext.Provider
      value={{
        selectedChat,
        setSelectedChat,
        newMessage,
        sendMessageSocket,
        sendCallSocket,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
}

export const useChatContext = () => useContext(ChatContext);

export default ChatContext;
