/* eslint-disable import/no-cycle */

import NoPageFound from "./NoPageFound";
import AUTH_PAGES from "./Auth";
import CLIENT_PAGES from "./Client";
import AudioCall from "./Client/AudioCall";

const WEB_PAGES = {
  AUTH_PAGES,
  CLIENT_PAGES,
  // Mo page found
  NO_PAGE_FOUND: NoPageFound,
  AUDIO_CALL: AudioCall,
};
export default WEB_PAGES;
