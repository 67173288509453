/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import FullScreenLoader from "../../components/Loader/FullScreenLoader";
import PATH from "../Path";

function PublicRoute() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!user) navigate(PATH.LOGIN);
    setTimeout(() => {
      if (user) {
        navigate(PATH.CHAT);
      }
    }, 500);
  }, [user]);

  if (user === undefined) return <FullScreenLoader />;

  return (
    <div className="vh-100">
      <Outlet />
    </div>
  );
}

export default PublicRoute;
