import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Toaster from "../../../components/Toaster";
import Chat from "./chat.service";

export const chatList = createAsyncThunk(
  "chat/all",
  async ({ startDate = null, endDate = null }, thunkAPI) => {
    try {
      const params = { startDate, endDate };
      const response = await Chat.getChatList(params);
      return response;
    } catch (error) {
      Toaster({ type: "error", message: error?.message });
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getChat = createAsyncThunk(
  "chat/one",
  async ({ chatId }, thunkAPI) => {
    try {
      const response = await Chat.getChat(chatId);
      return response;
    } catch (error) {
      Toaster({ type: "error", message: error?.message });
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const ShowRingingModal = createAsyncThunk(
  "showRingingModal",
  async ({ showRingingModal }, thunkAPI) => {
    try {
      return showRingingModal;
    } catch (error) {
      Toaster({ type: "error", message: error?.message });
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const VideoDetails = createAsyncThunk(
  "VideoDetails",
  async (videoDetails, thunkAPI) => {
    try {
      return videoDetails;
    } catch (error) {
      Toaster({ type: "error", message: error?.message });
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const chatTickets = createAsyncThunk(
  "chat/tickets",
  async (
    {
      page = 1,
      item = 5,
      status = null,
      startDate = null,
      endDate = null,
      username = null,
    },
    thunkAPI
  ) => {
    const params = { page, item, status, startDate, endDate, username };
    try {
      const response = await Chat.getChatTickets(params);
      return response;
    } catch (error) {
      Toaster({ type: "error", message: error?.message });
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createChatTicket = createAsyncThunk(
  "create/chat",
  async ({ payload, onFinish }, thunkAPI) => {
    try {
      //
      const response = await Chat.createChat(payload);
      if (response.status === 201) {
        onFinish(response?.data?.id);
      }
      return response;
    } catch (error) {
      Toaster({ type: "error", message: error?.message });
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateTicket = createAsyncThunk(
  "chat/update-ticket",
  async ({ chatId, payload, moveToNext }, thunkAPI) => {
    try {
      const response = await Chat.updateChatStatus(chatId, payload);
      if (response.status === 200) {
        Toaster({
          type: "success",
          message: "status updated successfully.",
        });
      }
      if (moveToNext) {
        moveToNext(response.data);
      }
      return response;
    } catch (error) {
      Toaster({ type: "error", message: error?.message });
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const FileUpload = createAsyncThunk(
  "fileUpload",
  async ({ data, moveToNext }, thunkAPI) => {
    try {
      const response = await Chat.fileUpload(data);

      if (response?.url) {
        if (moveToNext) {
          moveToNext(response);
        }
        Toaster({ type: "success", message: "File uploaded successfully!" });
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Toaster({ type: "error", message });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const GetFileByPathname = createAsyncThunk(
  "getFileByPathname",
  async ({ data, moveToNext }, thunkAPI) => {
    try {
      const response = await Chat.getFileByPathname(data);
      if (response?.url) {
        if (moveToNext) {
          moveToNext(response);
        }
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Toaster({ type: "error", message });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState = {
  chats: [],
  tickets: [],
  loading: false,
  isError: false,
  isErrorMessage: null,
  showRingingModal: false,
  videoDetails: null,

  createChatLoading: false,
  createChatError: false,
  createChatErrorMessage: false,

  isStatusLoading: false,
  isStatusError: false,
  isStatusErrorMessage: null,
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    reset: (state) => {
      state.chats = [];
      state.chat = null;
      state.loading = false;
      state.isError = false;
      state.isErrorMessage = null;
    },
    receiveNewMessage: (state, { payload }) => {
      const { newMessage } = payload;
      const updatedChats = [];

      state.chats.forEach((chat) => {
        if (chat.id === newMessage.chatId) {
          chat.conversations.unshift(newMessage);
          chat.lastMessage = newMessage.message;
          updatedChats.unshift(chat); // Add the updated chat to the beginning of the array
        } else {
          updatedChats.push(chat); // Add other chats to the end of the array
        }
      });

      state.chats = updatedChats; // Set the state with the reordered chats
    },
  },
  extraReducers: (builder) => {
    builder.addCase(chatList.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(chatList.fulfilled, (state, action) => {
      state.loading = false;
      state.chats = action.payload;
    });

    builder.addCase(chatList.rejected, (state, action) => {
      state.isErrorMessage = action.payload;
      state.loading = false;
      state.isError = true;
      state.chats = [];
    });

    builder.addCase(getChat.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getChat.fulfilled, (state, action) => {
      state.loading = false;
      state.chat = action.payload;
    });

    builder.addCase(getChat.rejected, (state, action) => {
      state.isErrorMessage = action.payload;
      state.loading = false;
      state.isError = true;
      state.chat = null;
    });

    builder.addCase(chatTickets.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(chatTickets.fulfilled, (state, action) => {
      state.loading = false;
      state.tickets = action.payload;
    });

    builder.addCase(chatTickets.rejected, (state, action) => {
      state.isErrorMessage = action.payload;
      state.loading = false;
      state.isError = true;
      state.tickets = null;
    });
    builder.addCase(updateTicket.pending, (state) => {
      state.isStatusLoading = true;
    });

    builder.addCase(updateTicket.fulfilled, (state) => {
      state.isStatusLoading = false;
    });

    builder.addCase(updateTicket.rejected, (state, action) => {
      state.isStatusErrorMessage = action.payload;
      state.isStatusLoading = false;
      state.isStatusError = true;
    });
    builder.addCase(createChatTicket.pending, (state) => {
      state.createChatLoading = true;
    });

    builder.addCase(createChatTicket.fulfilled, (state) => {
      state.createChatLoading = false;
    });

    builder.addCase(createChatTicket.rejected, (state, action) => {
      state.createChatErrorMessage = action.payload;
      state.createChatLoading = false;
      state.createChatError = true;
    });

    builder.addCase(GetFileByPathname.pending, (state) => {
      state.fileUploadLoading = true;
    });

    builder.addCase(GetFileByPathname.fulfilled, (state) => {
      state.fileUploadLoading = false;
    });

    builder.addCase(GetFileByPathname.rejected, (state, action) => {
      state.fileUploadErrorMessage = action.payload;
      state.fileUploadLoading = false;
      state.fileUploadError = true;
    });

    builder.addCase(ShowRingingModal.fulfilled, (state, action) => {
      state.showRingingModal = action.payload;
    });

    builder.addCase(VideoDetails.fulfilled, (state, action) => {
      state.videoDetails = action.payload;
    });
  },
});

export const chatActions = chatSlice.actions;

export default chatSlice.reducer;
