/* eslint-disable */
import { BsPersonFillGear } from "react-icons/bs";
import { HiUserGroup } from "react-icons/hi";
import { LuLayoutDashboard } from "react-icons/lu";
import { Link } from "react-router-dom";

import { Layout, Menu } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PATH from "../../../../Navigation/Path";
import useAuth, { PermissionEnum } from "../../../../hooks/useAuth";
import { useApp } from "../../../../context/appContext";

const { Sider } = Layout;

const MENU_ITEM_CHAT = {
  key: PATH.CHAT,
  icon: <LuLayoutDashboard className="sider-icon" size={20} />,
  label: <Link to={PATH.CHAT}>Chat</Link>,
};

const MENU_ITEM_TICKET = {
  key: PATH.TICKET,
  icon: <BsPersonFillGear className="sider-icon" size={20} />,
  label: <Link to={PATH.TICKET}>Tickets</Link>,
};

const MENU_ITEM_GROUP = {
  key: PATH.GROUP,
  icon: <HiUserGroup className="sider-icon" size={20} />,
  label: <Link to={PATH.GROUP}>Groups</Link>,
};

const SidebarMenu = () => {
  const location = useLocation();
  const { userCan } = useAuth();
  const { hasMenu, setHasMenu } = useApp();
  const [sidebarItems, setSidebarItems] = useState([]);
  const [selectedKey, setSelectedKey] = useState(location.pathname);
  const liveChatScreens = [PATH.LIVE_CHAT, PATH.NEW_CHAT];

  useEffect(() => {
    const selected = sidebarItems.find((item) => {
      return location.pathname.includes(item.key);
    });

    if (selected) {
      setSelectedKey(selected.key);
    }
  }, [location.pathname, sidebarItems]);

  useEffect(() => {
    const sidebarItems = [];
    if (userCan(PermissionEnum.LIST_CHATS)) {
      sidebarItems.push(MENU_ITEM_CHAT);
    }
    if (userCan(PermissionEnum.LIST_TICKETS)) {
      sidebarItems.push(MENU_ITEM_TICKET);
    }
    if (userCan(PermissionEnum.LIST_GROUPS)) {
      sidebarItems.push(MENU_ITEM_GROUP);
    }

    setSidebarItems(sidebarItems);
    setHasMenu(sidebarItems.length > 1);
  }, []);

  if (!hasMenu) {
    return <></>;
  }

  return (
    <Sider
      className={`sidebar ${
        liveChatScreens.includes(location.pathname) ? "d-none" : ""
      }`}
      breakpoint="lg"
      trigger={null}
      width="260px"
    >
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={selectedKey}
        items={sidebarItems}
      />
      <div style={{ height: "100px" }} />
    </Sider>
  );
};

export default SidebarMenu;
