/* eslint-disable import/no-named-as-default */
/* eslint-disable no-unused-vars */
/* eslint-disable */
import { useCallback, useEffect, useRef, useState } from "react";

import {
  Button,
  Card,
  Dropdown,
  Input,
  message,
  Tooltip,
  Typography,
  Upload,
} from "antd";
import axios from "axios";
import { Formik } from "formik";
import moment from "moment";
import { AiOutlineSearch, AiOutlineUser } from "react-icons/ai";
import { BsDot, BsThreeDotsVertical } from "react-icons/bs";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { HiPhone } from "react-icons/hi";
import {
  IoIosArrowBack,
  IoIosSend,
  IoMdAttach,
  IoMdClose,
} from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import * as Yup from "yup";
import {
  FileUpload,
  getChat,
  GetFileByPathname,
  ShowRingingModal,
  updateTicket,
  VideoDetails,
} from "../../../app/features/chat/chat.slice";
import {
  getMessagesListByChatId,
  readMessages,
  sendMessage,
  UpdateCallingStatus,
} from "../../../app/features/conversations/conversation.slice";
import { TicketStatus } from "../../../constants";
import { useChatContext } from "../../../context/chatContext";
import useAuth, { PermissionEnum } from "../../../hooks/useAuth";
import TitleEdit from "../../../pages/Client/LiveChat/components/TitleEdit";
import { API_BASE_URL } from "../../../utils/api";
import HtmlConverter, {
  replaceTimezoneWithCurrent,
} from "../../../utils/helpers";
import FileIcon from "../../FileIcon/FileIcon";
import Toaster from "../../Toaster";
import AddMember from "./components/AddMember";
import ToolbarRight from "./components/ToolbarRight";

function Chat({ chatId }) {
  const { user, userCan, isAdmin, token } = useAuth();
  const navigate = useNavigate();

  const { selectedChat, setSelectedChat, sendMessageSocket, sendCallSocket } =
    useChatContext();
  const [addMemberModalOpen, setAddMemberModalOpen] = useState(false);

  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const chatReducer = useSelector((state) => state.chat);
  const { messages } = useSelector((state) => state.conversation);

  const [currentFoundMessageId, setCurrentFoundMessageId] = useState(null);
  const [countFoundMessage, setCountFoundMessage] = useState(null);
  const [highlightedMessageIds, setHighlightedMessageIds] = useState([]);
  const [conversations, setConversations] = useState(messages);
  const [isUploading, setIsUploading] = useState(false);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);

  const messagesContainerRef = useRef(null);

  useEffect(() => {
    if (!chatId) return;
    dispatch(getChat({ chatId }));
  }, [chatId]);

  useEffect(() => {
    if (!chatReducer.chat) return;

    const currentChat = chatReducer.chat;

    setSelectedChat({
      groupName: currentChat?.groups.groupName,
      id: currentChat?.id,
      groupId: currentChat?.groupId,
      groupMembers: currentChat?.groups?.users,
      title: currentChat?.title,
      currentStatus: currentChat?.currentStatus,
      createdAt: currentChat?.createdAt,
      updatedAt: currentChat?.updatedAt,
      sender: currentChat?.sender,
      isCalling:
        currentChat?.activeCallers === null ||
        currentChat?.activeCallers.length === 0
          ? currentChat?.isCalling === true && false
          : true,
      ticketId: currentChat?.ticketId,
    });

    if (
      currentChat.activeCallers === null ||
      currentChat.activeCallers.length === 0
    ) {
      if (currentChat.isCalling === true) {
        dispatch(
          UpdateCallingStatus({
            data: {
              chatId: currentChat.id.toString(),
              isActive: false,
            },
            moveToNext: () => {},
          })
        );
      }
    }

    dispatch(readMessages({ chatId: currentChat?.id }));
  }, [chatReducer.chat]);

  // Function to scroll to the bottom of the messages container
  useEffect(() => {
    if (!messagesContainerRef.current) {
      return;
    }

    messagesContainerRef.current.scrollTo({
      top: messagesContainerRef.current.scrollHeight,
      behavior: "instant",
    });
  }, [conversations, isUploading]);

  useEffect(() => {
    return () => {
      setSelectedChat(null);
    };
  }, []);

  useEffect(() => {
    if (!selectedChat) {
      setSearch("");
      setSearchInput("");
      setIsSearchExpanded(false);
      return;
    }

    dispatch(getMessagesListByChatId({ chatId: selectedChat?.id }));
  }, [selectedChat]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleFileChange = (info) => {
    const { status, fileList } = info;

    if (status !== "uploading" && fileList?.length > 0) {
      const formData = new FormData();
      formData.append("file", fileList[0].originFileObj);
      formData.append("type", "support");

      try {
        dispatch(
          FileUpload({
            data: formData,
            moveToNext: (response) => {
              const payload = {
                message: "Uploaded File",
                file: response?.filePath,
                fileType: info.file.type,
              };

              dispatch(
                sendMessage({
                  chatId: selectedChat?.id,
                  payload,
                })
              );
              sendMessageSocket(payload);
              setIsUploading(false);
            },
          })
        );
      } catch (error) {
        setIsUploading(false);
        console.log("Error ....", error);
      }
    }
  };

  const uploadProps = {
    name: "file",
    accept: ".doc,.docx,application/pdf, image/*",
    beforeUpload: () => {
      setIsUploading(true);
      return false;
    },
    onChange(info) {
      const { status } = info.file;

      if (status !== "uploading") {
        console.log("info.file", info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const schema = Yup.object().shape({
    message: Yup.string(),
  });

  const handleSearchInput = () => {
    setIsSearchExpanded(!isSearchExpanded);
    setSearch("");
    setSearchInput("");
  };

  const goTo = useCallback(
    (messageId) => {
      if (!messagesContainerRef) return;

      const targetMessage = messagesContainerRef.current?.querySelector(
        `.message-${messageId}`
      );
      if (targetMessage) {
        const topPos = targetMessage.offsetTop;
        messagesContainerRef.current.scrollTo({
          top: topPos - 100, // - window.innerHeight / 2
          behavior: "smooth",
        });
      }
    },
    [messagesContainerRef]
  );

  const previousFoundMessage = () => {
    const previousKey = currentFoundMessageId - 1;
    if (previousKey < 1) {
      return;
    }
    setCurrentFoundMessageId(previousKey);
    goTo(highlightedMessageIds[previousKey - 1]);
  };

  const nextFoundMessage = useCallback(() => {
    const nextKey = currentFoundMessageId + 1;
    if (nextKey > highlightedMessageIds.length) {
      return;
    }
    setCurrentFoundMessageId(nextKey);
    goTo(highlightedMessageIds[nextKey - 1]);
  }, [currentFoundMessageId, highlightedMessageIds, goTo]);

  const getSenderAvatar = (sentId) => {
    if (!selectedChat) return;

    const members = [...selectedChat?.groupMembers, selectedChat?.sender];

    const member = members.find((member) => member.id === sentId);

    if (member) {
      return member.avatar;
    }

    return "";
  };

  useEffect(() => {
    if (!search) {
      setConversations(messages);
      setCurrentFoundMessageId(null);
      setCountFoundMessage(null);
      setConversations((conversations) =>
        conversations.map((conversation) => {
          return {
            ...conversation,
            highlightedMessage: null,
            senderAvatar: getSenderAvatar(conversation.sentBy),
          };
        })
      );
      return;
    }

    let countFound = 0;
    const highlightedIds = [];
    const conversation = messages?.map((item) => {
      const { message } = item;
      const searchIndex = message.toLowerCase().indexOf(search.toLowerCase());
      if (searchIndex !== -1) {
        countFound++;
        highlightedIds.push(item.id);
        const beforeMatch = message.substring(0, searchIndex);
        const match = message.substring(
          searchIndex,
          searchIndex + search.length
        );
        const afterMatch = message.substring(searchIndex + search.length);
        const highlightedMessage = `${beforeMatch}<span class="highlight">${match}</span>${afterMatch}`;
        return {
          ...item,
          highlightedMessage,
          senderAvatar: getSenderAvatar(item.sentBy),
        };
      }
      return item;
    });

    setConversations(conversation);
    setHighlightedMessageIds(highlightedIds);
    setCountFoundMessage(countFound);
    setCurrentFoundMessageId(0);
  }, [search, messages]);

  useEffect(() => {
    if (highlightedMessageIds.length === 0) {
      return;
    }
    nextFoundMessage();
  }, [highlightedMessageIds]);

  let typingTimeout;
  const handleMessageSearch = (e) => {
    const { value } = e.target;
    setSearchInput(value);
    clearTimeout(typingTimeout);
    typingTimeout = setTimeout(() => {
      setSearch(value);
    }, 500);
  };

  function confirmChangeStatus(status) {
    const text =
      status === 2
        ? "Will you close this chat?"
        : "Are you going to escalate this ticket?";

    Swal.fire({
      title: "Status Update",
      text: text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#40BEEE",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then((result) => {
      if (result.isConfirmed) {
        updateThreadStatus(status);
        navigate("/chat");
      }
    });
  }

  function updateThreadStatus(currentStatus) {
    dispatch(
      updateTicket({ chatId: selectedChat.id, payload: { currentStatus } })
    );
    setSelectedChat((prev) => ({ ...prev, currentStatus }));
  }

  async function handleCall() {
    await axios
      .post(`${API_BASE_URL}/vonage/vonage-sessions`, null, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        let finalGroupMembers = [
          ...selectedChat?.groupMembers,
          selectedChat?.sender,
        ];

        let callingPayload = {
          // sentBy: user?.id,
          aeId: null,
          roomMembers: finalGroupMembers.filter(
            (item) => item?.id !== user?.id
          ),
          name: user?.fullName,
          avatar: user?.avatar,
          // callerId: socket?.id,
          callerId: user?.id,
          sessionId: response.data.sessionId,
          token: response.data.token,
          chatId: selectedChat?.id,
          groupId: selectedChat?.groupId,
        };
        console.log("callingPAyload", callingPayload);
        try {
          dispatch(VideoDetails(callingPayload));
          sendCallSocket(callingPayload);

          console.log("socket done");
          // setShow(true);
          dispatch(ShowRingingModal({ showRingingModal: true }));

          // navigate("/audio-call", {
          //   state: { callingDetails: callingPayload },
          // });
        } catch (error) {
          Toaster({ type: "error", message: `Socket error:  ${error}` });
          console.log("socket error");
        }
      })
      .catch((error) => {
        Toaster({
          type: "error",
          message: `There is some issue in calling (Vonage):  ${error}`,
        });
        console.log("error", error);
      });
  }

  // const [api, contextHolder] = notification.useNotification();

  // const openNotification = () => {
  //
  //   api.info({
  //     message: "Notification Title",
  //     description:
  //       "I will never close automatically. This is a purposely very very long description that has many many characters and words.",
  //     duration: 3,
  //   });
  // };

  // const [rejectCall, setRejectCall] = useState(null);
  // useEffect(() => {
  //   if (!socket) return;

  //   socket.on("callNotification", ({ name, avatar, callerId, message }) => {
  //     setRejectCall({
  //       callerId,
  //       name,
  //       avatar,
  //       message,
  //     });
  //     console.log("reject call data", {
  //       callerId,
  //       name,
  //       avatar,
  //       message,
  //     });

  //     // openNotification();
  //     // Toaster("Call ended!");
  //     // navigate(-1);
  //   });
  // }, [socket]);
  // console.log("rejectCall", rejectCall);

  const items = [
    {
      label: (
        <div
          role="presentation"
          // className="w-100 text-center"
          onClick={(e) => updateThreadStatus(0)}
        >
          OPEN
        </div>
      ),
      key: 0,
    },
    {
      label: (
        <div
          role="presentation"
          // className="w-100 text-center"
          onClick={(e) => updateThreadStatus(1)}
        >
          IN PROGRESS
        </div>
      ),
      key: 1,
    },
    {
      label: (
        <div
          role="presentation"
          // className="w-100 text-center"
          onClick={(e) => confirmChangeStatus(2)}
        >
          CLOSE
        </div>
      ),
      key: 2,
    },
    {
      label: (
        <div
          role="presentation"
          // className="w-100 text-center"
          onClick={(e) => confirmChangeStatus(3)}
        >
          ESCALATED
        </div>
      ),
      key: 3,
    },
  ];

  return (
    <>
      {/* {contextHolder} */}
      <div
        className={`chat-parent  ${
          windowWidth < 985 && selectedChat !== null ? "mobile-chat-vew" : ""
        }`}
      >
        {selectedChat && (
          <div
            className={`chat-card ${
              windowWidth < 985 &&
              selectedChat !== null &&
              selectedChat !== undefined
                ? "w-100"
                : windowWidth > 985
                ? ""
                : "d-none"
            }
    `}
          >
            <div className="messages-header d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-start">
                <IoIosArrowBack
                  size={18}
                  className="mr-2 mt-1 d-block d-lg-none cursor-pointer"
                  onClick={() => setSelectedChat(null)}
                />
                <div>
                  <div className="fs-sm text-black fw-normal d-flex align-items-center">
                    {isAdmin
                      ? selectedChat?.sender?.fullName
                      : selectedChat?.groupName}
                    <BsDot className="text-success" size={28} />
                  </div>

                  <TitleEdit />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {selectedChat?.ticketId && (
                  <>
                    <Tooltip title="Ticket ID">
                      <Typography
                        style={{ fontWeight: "bold", color: "#87cb60" }}
                      >
                        {selectedChat?.ticketId.toUpperCase()}
                      </Typography>
                    </Tooltip>
                    <small>
                      {replaceTimezoneWithCurrent(selectedChat?.createdAt)}
                    </small>
                    <div className="d-flex align-items-center justify-content-center">
                      <small className="mb-0">
                        <b>Status:</b>
                      </small>
                      &nbsp;
                      <small>
                        {TicketStatus[+selectedChat?.currentStatus].label}
                      </small>
                    </div>
                  </>
                )}
              </div>
              <div className="d-flex align-items-center">
                <div
                  className={`d-xl-flex align-items-center d-none search-bar mr-2  ${
                    isSearchExpanded ? "expanded" : ""
                  }`}
                >
                  {!isSearchExpanded && (
                    <button
                      type="button"
                      className="icon-circle-bg cursor-pointer shadow-lg collapse-search"
                      onClick={handleSearchInput}
                    >
                      <AiOutlineSearch size={18} />
                    </button>
                  )}
                  <Input
                    name="search"
                    onChange={(e) => handleMessageSearch(e)}
                    type="text"
                    value={searchInput}
                    placeholder="Search"
                    autoFocus
                    suffix={
                      isSearchExpanded && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "8px",
                          }}
                        >
                          {countFoundMessage && (
                            <small>
                              {currentFoundMessageId}/{countFoundMessage}
                            </small>
                          )}
                          <Button
                            size="small"
                            style={{
                              width: "18px",
                              minWidth: "18px",
                              height: "18px",
                            }}
                            disabled={!countFoundMessage}
                            shape="circle"
                            onClick={() => {
                              previousFoundMessage();
                            }}
                            icon={<FaAngleUp />}
                          />
                          <Button
                            size="small"
                            style={{
                              width: "18px",
                              minWidth: "18px",
                              height: "18px",
                            }}
                            disabled={!countFoundMessage}
                            shape="circle"
                            onClick={() => {
                              nextFoundMessage();
                            }}
                            icon={<FaAngleDown />}
                          />
                          <Button
                            size="small"
                            style={{
                              width: "18px",
                              minWidth: "18px",
                              height: "18px",
                            }}
                            shape="circle"
                            icon={<IoMdClose />}
                            onClick={handleSearchInput}
                          />
                        </div>
                      )
                    }
                  />
                </div>

                {selectedChat?.isCalling ? (
                  <div
                    className="icon-circle-bg mr-2 cursor-pointer  shadow-lg"
                    role="presentation"
                    onClick={() =>
                      Toaster({
                        type: "warning",
                        message: "You cannot make call!",
                      })
                    }
                  >
                    <HiPhone size={16} className="text-success" />
                  </div>
                ) : (
                  <div
                    className="icon-circle-bg mr-2 cursor-pointer shadow-lg"
                    role="presentation"
                    onClick={() => handleCall()}
                  >
                    <HiPhone size={16} className="text-black" />
                  </div>
                )}

                {userCan(PermissionEnum.LIST_MEMBERS) && (
                  <div
                    className="icon-circle-bg cursor-pointer shadow-lg mr-2"
                    role="presentation"
                    onClick={() => setAddMemberModalOpen(true)}
                  >
                    <AiOutlineUser size={14} className="text-black" />
                  </div>
                )}

                {userCan(PermissionEnum.CHANGE_STATUS_ASSIGNED_TICKETS) && (
                  <Dropdown
                    className="mr-2"
                    menu={{
                      items,
                    }}
                    placement="bottomRight"
                    trigger={["click"]}
                  >
                    <div className="icon-circle-bg cursor-pointer shadow-lg">
                      <BsThreeDotsVertical size={14} className="text-black" />
                    </div>
                  </Dropdown>
                )}
              </div>
            </div>
            <div className="messages-header mt-1">
              <hr />
            </div>
            <div className="messages-container " ref={messagesContainerRef}>
              <ul className="px-0">
                {conversations?.map((item) => (
                  <li
                    key={item?.id}
                    className={`mt-3 ${
                      item?.sentBy === user?.id
                        ? "message-sent"
                        : "message-received"
                    } message-${item?.id}`}
                  >
                    {item?.sentBy === user?.id ? (
                      <></>
                    ) : (
                      <div
                        style={{ width: "35px", height: "35px" }}
                        className="rounded-circle mr-2"
                      >
                        <img
                          src={
                            item?.senderAvatar ||
                            `https://ui-avatars.com/api/?name=${item?.senderName}&background=17406d&color=fff`
                          }
                          className="w-100 rounded-circle"
                        />
                      </div>
                    )}
                    <div className="mw-70">
                      {item?.sentBy !== user?.id ? (
                        <div className="d-flex align-items-center justify-content-between">
                          <small className="text-black fw-bold mr-3">
                            {item?.senderName ?? ""}
                          </small>{" "}
                          <small className="m-0">
                            {moment(item?.created_at).format("hh:mm a")}
                          </small>
                        </div>
                      ) : (
                        <></>
                      )}
                      <Card className="m-0">
                        <span className="text-black">
                          {item?.fileType ? (
                            <>
                              <div
                                style={{ cursor: "pointer" }}
                                className="m-0"
                                onClick={() => {
                                  if (item?.file) {
                                    dispatch(
                                      GetFileByPathname({
                                        data: item.file,
                                        moveToNext: (response) => {
                                          window.open(response.url, "_blank");
                                        },
                                      })
                                    );
                                  }
                                }}
                              >
                                {<FileIcon conversation={item} />}
                              </div>

                              <HtmlConverter
                                message={
                                  item?.highlightedMessage || item?.message
                                }
                              />
                              {item?.sentBy === user?.id && (
                                <div className="mt-0">
                                  <div className="m-0 d-flex justify-content-end">
                                    {moment(item?.created_at).format("hh:mm a")}
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            <HtmlConverter
                              message={
                                item?.highlightedMessage || item?.message
                              }
                            />
                          )}
                        </span>
                        {item?.sentBy !== user?.id ? (
                          <></>
                        ) : (
                          //  item.fileType ? (
                          //   <></>
                          // ) :
                          !(item?.sentBy === user?.id && item.file) && (
                            <span>
                              <div>
                                {moment(item?.created_at).format("hh:mm a")}
                              </div>
                            </span>
                          )
                          // <></>
                        )}
                      </Card>
                    </div>
                  </li>
                ))}
                {isUploading && (
                  <li className="mt-3 message-sent">
                    <div className="mw-70">
                      <Card className="m-0">
                        <span className="text-black">
                          <div className="m-0">{<FileIcon />}</div>

                          {/* <HtmlConverter message={"Uploading ..."} /> */}
                        </span>
                      </Card>
                    </div>
                  </li>
                )}
              </ul>
            </div>

            {+selectedChat?.currentStatus !== 2 ? (
              <Formik
                validationSchema={schema}
                initialValues={{ message: "" }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  if (values.message) {
                    if (selectedChat)
                      dispatch(
                        sendMessage({
                          chatId: selectedChat?.id,
                          payload: {
                            message: values.message,
                            name: user?.fullName,
                          },
                        })
                      );

                    sendMessageSocket({ message: values.message });

                    resetForm();
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="send-message-wrapper">
                      <div className="send-message-inner-wrapper w-100 d-flex align-items-center">
                        <Input
                          className="pl-3 send-message mr-3"
                          placeholder={`${
                            chatReducer?.fileUploadLoading
                              ? "Uploading..."
                              : "Type here..."
                          }`}
                          type="text"
                          name="message"
                          autoComplete="off"
                          autoFocus
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.message}
                          disabled={chatReducer?.fileUploadLoading}
                          // required={!!errors?.message}
                        />

                        <div className={`upload-icon cursor-pointer`}>
                          <Upload
                            {...uploadProps}
                            onChange={handleFileChange}
                            maxCount={1}
                          >
                            <IoMdAttach size={22} />
                          </Upload>
                        </div>
                      </div>
                      <button type="submit" className="border-0 bg-transparent">
                        <div className="send-icon-bg cursor-pointer">
                          <IoIosSend className="text-white" size={28} />
                        </div>
                      </button>
                    </div>
                    {errors.message && touched.message ? (
                      <span className="my-2 px-2" style={{ color: "red" }}>
                        {errors.message}
                      </span>
                    ) : null}
                  </form>
                )}
              </Formik>
            ) : (
              <p class="text-center">
                You can not send message because this chat was{" "}
                <b>{TicketStatus[+selectedChat?.currentStatus].label}</b>
              </p>
            )}
          </div>
        )}

        <ToolbarRight />

        <AddMember
          modalOpen={addMemberModalOpen}
          setModalOpen={setAddMemberModalOpen}
          groupId={selectedChat?.groupId}
        />
      </div>
    </>
  );
}

export default Chat;
