/* eslint-disable import/no-named-as-default */
/* eslint-disable no-unused-vars */
/* eslint-disable */

import { Spin } from "antd";
import { BsFileEarmarkWord, BsFiletypePdf, BsImage } from "react-icons/bs";
import { TbFileUpload } from "react-icons/tb";

export default function FileIcon({ conversation }) {
  const iconSize = 70;
  const containerStyle = {
    border: "solid 1px #87cb60",
    borderRadius: "4px",
    padding: "12px",
    color: "#87cb60",
  };

  if (!conversation) {
    return (
      <div>
        <Spin
          tip={
            <h5 style={{ color: containerStyle.color, paddingTop: "10px" }}>
              Uploading...
            </h5>
          }
          size="large"
        >
          <TbFileUpload size={90} color="#87cb60" />
        </Spin>
      </div>
    );
  }

  if (conversation?.fileType?.includes("image")) {
    return (
      <div style={containerStyle}>
        <BsImage size={iconSize} />
      </div>
    );
  }

  if (conversation?.fileType === "application/pdf") {
    return (
      <div style={containerStyle}>
        <BsFiletypePdf size={iconSize} />
      </div>
    );
  }
  return (
    <div style={containerStyle}>
      <BsFileEarmarkWord size={iconSize} />
    </div>
  );
}
