/* eslint-disable no-unused-expressions */
/* eslint-disable import/order */
import { Button, Col, Modal, Row } from "antd";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { IoMdCall } from "react-icons/io";
import { useDispatch } from "react-redux";
// eslint-disable-next-line import/no-cycle
import { BeatLoader } from "react-spinners";
import { ShowRingingModal } from "../../../../app/features/chat/chat.slice";

function RingingModal({ show, callDetails, toggleSound, cancelCall }) {
  const dispatch = useDispatch();

  useEffect(() => {
    toggleSound(show);
  }, [show]);

  return (
    <Modal
      className="add-group-modal mx-auto py-0"
      title=""
      centered
      open={show}
      closable={false}
      width={300}
      height={200}
      footer={null}
      destroyOnClose
    >
      <Row gutter={24} className="d-flex align-items-center flex-column">
        <Col md={24}>
          <h3>Ringing</h3>
        </Col>
        <Col md={24}>
          <BeatLoader />
        </Col>

        <Col md={24}>
          <Button
            className="p-0 rounded-circle d-flex justify-content-center align-items-center bg-danger mt-2"
            style={{
              width: "40px",
              height: "40px",
            }}
            onClick={() => {
              callDetails && cancelCall(callDetails);
              toggleSound(false);
              dispatch(ShowRingingModal({ showRingingModal: false }));
            }}
          >
            <IoMdCall className="text-white" size={20} />
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}

export default RingingModal;

RingingModal.propTypes = {
  show: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  callDetails: PropTypes.node,
  toggleSound: PropTypes.func.isRequired,
  cancelCall: PropTypes.func.isRequired,
};
