import { toast } from "react-toastify";
import PropTypes from "prop-types";

function Toaster({ type, message }) {
  toast(message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    type,
  });
}

export default Toaster;

Toaster.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
};

Toaster.defaultProps = {
  message: "Toaster",
  type: "success",
};
