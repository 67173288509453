/* eslint-disable */
import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import MainLayout from "../components/Layout/Private/MainLayout";

import useAuth from "../hooks/useAuth";
import WEB_PAGES from "../pages";
import PATH from "./Path";
import PrivateRoutes from "./Routes/ProtectedRoute";
import PublicRoute from "./Routes/PublicRoute";

const CLIENT_ROUTES = [
  {
    name: "Ticket",
    path: PATH.TICKET,
    page: () => <WEB_PAGES.CLIENT_PAGES.TICKET />,
  },
  {
    name: "Messaging",
    path: PATH.CHAT,
    page: () => <WEB_PAGES.CLIENT_PAGES.CHAT />,
  },
  {
    name: "Messaging",
    path: PATH.CHAT_ID,
    page: () => <WEB_PAGES.CLIENT_PAGES.CHAT_ID />,
  },
  {
    name: "Group",
    path: PATH.GROUP,
    page: () => <WEB_PAGES.CLIENT_PAGES.GROUP />,
  },
  {
    name: "Account Settings",
    path: PATH.ACCOUNT_SETTINGS,
    page: () => <WEB_PAGES.CLIENT_PAGES.ACCOUNT_SETTINGS />,
  },
];

const AE_ROUTES = [
  {
    name: "Live Chat",
    path: PATH.LIVE_CHAT,
    page: () => <WEB_PAGES.CLIENT_PAGES.LIVE_CHAT.INDEX />,
  },
  {
    name: "Messaging",
    path: PATH.CHAT,
    page: () => <WEB_PAGES.CLIENT_PAGES.CHAT />,
  },
  {
    name: "Messaging",
    path: PATH.CHAT_ID,
    page: () => <WEB_PAGES.CLIENT_PAGES.CHAT_ID />,
  },
  {
    name: "New Chat",
    path: PATH.NEW_CHAT,
    page: () => <WEB_PAGES.CLIENT_PAGES.LIVE_CHAT.NEW_CHAT />,
  },
];

export default function AllRoutes() {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) navigate(PATH.LOGIN);
  }, [user]);

  return (
    <Routes>
      {/* Public Routes */}
      <Route element={<PublicRoute />}>
        <Route index element={<WEB_PAGES.AUTH_PAGES.LOGIN />} />
        <Route path={PATH.SIGN_UP} element={<WEB_PAGES.AUTH_PAGES.SIGN_UP />} />
      </Route>
      <Route
        path={PATH.FORGET_PASSWORD}
        element={<WEB_PAGES.AUTH_PAGES.FORGET_PASSWORD />}
      />

      {/* private routes */}
      <Route element={<PrivateRoutes />}>
        <Route element={<MainLayout />}>
          {CLIENT_ROUTES?.map((route) => (
            <Route key={route.path} path={route.path} element={route.page()} />
          ))}
          {AE_ROUTES?.map((route, index) => (
            <Route key={index} path={route.path} element={route.page()} />
          ))}
        </Route>
        <Route
          key={PATH.AUDIO_CALL}
          path={PATH.AUDIO_CALL}
          element={<WEB_PAGES.AUDIO_CALL />}
        />
      </Route>

      {/* Missing/page not found */}
      <Route path="*" element={<WEB_PAGES.NO_PAGE_FOUND />} />
    </Routes>
  );
}
