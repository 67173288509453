/* eslint-disable */
import { Button, Form, Input, Space } from "antd";
import { useEffect, useState } from "react";
import { IoIosSave, IoMdCreate } from "react-icons/io";
import { useDispatch } from "react-redux";
import { UpdateTitle } from "../../../../app/features/conversations/conversation.slice";
import { useChatContext } from "../../../../context/chatContext";
import useAuth, { PermissionEnum } from "../../../../hooks/useAuth";

const TitleLabel = () => {
  return <strong>Title: </strong>;
};

function TitleEdit() {
  const { isAdmin, userCan } = useAuth();
  const { selectedChat } = useChatContext();

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(selectedChat?.title);

  const handleInputChange = (e) => {
    setTitle(e.target.value);
  };

  const updateTitle = () => {
    setIsEditing(false);
    dispatch(UpdateTitle({ chatId: selectedChat?.id, title }));
  };

  useEffect(() => {
    setTitle(selectedChat?.title);
  }, [selectedChat]);

  useEffect(() => {
    if (!isAdmin) {
      setIsEditing(false);
    }
  }, [isAdmin]);

  if (!isEditing) {
    return (
      <div style={{ height: "40px" }}>
        <TitleLabel />
        &nbsp;
        {title}
        &nbsp;{" "}
        {userCan(PermissionEnum.CHANGE_NAME_CHATS) && (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setIsEditing(true);
            }}
          >
            <IoMdCreate />
          </a>
        )}
      </div>
    );
  }

  return (
    <Form
      name="title_form"
      autoComplete="off"
      form={form}
      layout="horizontal"
      requiredMark={false}
      initialValues={{ title }}
      style={{ height: "40px" }}
    >
      <Form.Item
        name="title"
        rules={[
          { required: true, message: "Please input title!" },
          { maxLength: 100 },
        ]}
        label={<TitleLabel />}
        style={{ marginBottom: "0px" }}
      >
        <Space.Compact size="small" style={{ width: "100%" }}>
          <Input
            size="small"
            placeholder="Insert title"
            maxLength={100}
            style={{ height: "24px" }}
            value={title}
            onChange={handleInputChange}
          />
          <Button type="primary" onClick={updateTitle}>
            <IoIosSave />
          </Button>
        </Space.Compact>
      </Form.Item>
    </Form>
  );
}

export default TitleEdit;
TitleEdit.propTypes = {};
