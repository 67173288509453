import Api from "../../../utils/api";

async function getChatGroupList(params) {
  const response = await Api.get(
    `chat-groups/?page=${params?.page}&item=${params?.item}&title=${params?.title}&status=${params.status}&start_date=${params.startDate}&end_date=${params?.endDate}`
  );
  return response;
}
async function getGroups() {
  const response = await Api.get(`groups`);
  return response;
}

async function createChatGroup(payload) {
  const response = await Api.post("chat-group", payload);
  return response;
}

async function getGroupById(groupId) {
  const response = await Api.get(`chat-group/${groupId}`);
  return response;
}

async function addMemberToChatGroup(groupId, payload) {
  const response = await Api.put(`chat-group/${groupId}/add-users`, payload);
  return response;
}

async function removeMemberFromChatGroup(groupId, payload) {
  const response = await Api.put(`chat-group/${groupId}/remove-users`, payload);
  return response;
}

async function destroyChatGroup(id) {
  const response = await Api.delete(`chat-groups/${id}`);
  return response;
}

async function restoreChatGroup(groupId) {
  const response = await Api.patch(`chat-groups-reinstate/${groupId}`);
  return response.data;
}

async function chatDepartments() {
  const response = await Api.get(`/lists/roles`);
  return response.data;
}

async function updateChatGroupStatus(groupId, payload) {
  const response = await Api.put(
    `chat-group/${groupId}/update-status`,
    payload
  );
  return response.data;
}

async function updateChatGroup(groupId, payload) {
  const response = await Api.put(`chat-group/${groupId}/edit`, payload);
  return response.data;
}

const ChatGroups = {
  updateChatGroup,
  updateChatGroupStatus,
  getChatGroupList,
  createChatGroup,
  destroyChatGroup,
  addMemberToChatGroup,
  removeMemberFromChatGroup,
  restoreChatGroup,
  chatDepartments,
  getGroups,
  getGroupById,
};

export default ChatGroups;
