import axios from "axios";

export const API_BASE_URL = process.env.REACT_APP_API_URL;

const Api = axios.create({
  baseURL: API_BASE_URL,
});

const user = localStorage.getItem("uml_user");
const auth = user ? JSON.parse(user) : null;

Api.interceptors.request.use(
  (config) => {
    config.headers = config.headers || {}; // Initialize headers if undefined

    if (auth) {
      const { token } = auth;

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

Api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      error.response.status === 406 ||
      error.response.status === 403 ||
      error.response.status === 401
    ) {
      localStorage.removeItem("uml_user");
      window.location = "/login";
      return;
    }

    Promise.reject(error);
  }
);

export default Api;
