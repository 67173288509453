/* eslint-disable */
import Api from "../../../utils/api";

const login = async (userData) => {
  const result = await Api.post("auth/login", userData);
  if (result.status === 200) {
    localStorage.setItem("uml_user", JSON.stringify(result.data));
  }
  return result;
};

const register = async (req) => {
  const { data } = await Api.post(`/account/register`, req);
  if (data?.succeeded) {
    return data.data;
  } else throw data.message;
};

// Logout user
const logout = async () => {
  const response = await Api.post(`auth/logout`);
  if (response.status === 200) {
    localStorage.removeItem("uml_user");
  }
  return response;
};

// Logout user
export const userOnlineStatus = async (payload) => {
  const response = await Api.put(`user/update-online-status`, payload);
  return response;
};

const authService = {
  logout,
  login,
  register,
  userOnlineStatus,
};

export default authService;
