/* eslint-disable import/no-cycle */

import React from "react";

const INDEX = React.lazy(() => import("./LiveChat.jsx"));
const NEW_CHAT = React.lazy(() => import("./NewChat.jsx"));

const LIVE_CHAT = {
  INDEX,
  NEW_CHAT,
};
export default LIVE_CHAT;
