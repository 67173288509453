/* eslint-disable */
import React, { useRef } from "react";
import { OTPublisher } from "opentok-react";
import CheckBox from "./CheckBox";
import { MdCall } from "react-icons/md";
import { AiOutlineAudio, AiOutlineAudioMuted } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import IMAGES from "../../../assets/images";
import { BsDot, BsFillCameraVideoFill, BsFillCameraVideoOffFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { RiChat4Fill, RiChat4Line, RiChatOffFill } from "react-icons/ri";

class Publisher extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      audio: true,
      video: true,
      videoSource: "camera",
    };
  }
  setAudio = (audio) => {
    console.log("audio>>>>", audio);
    this.setState({ audio: !this.state.audio });

  };

  setVideo = (video) => {
    this.setState({ video: !this.state.video });
  };

  changeVideoSource = (videoSource) => {
    this.state.videoSource !== "camera"
      ? this.setState({ videoSource: "camera" })
      : this.setState({ videoSource: "screen" });
  };

  onError = (err) => {
    this.setState({ error: `Failed to publish: ${err.message}` });
  };
  discounthandlerEnd = () => {
    this.props.endSession();

    // this.props.navigation.goBack();

    // await otSession.current.sessionHelper.session.forceDisconnect(otSession.current.state.streams[0].connection);
  };

  // Send a "user-join" signal when the user joins the call
  // sendUserJoinSignal = () => {
  //   const { otSession } = this.props;
  //   const user = JSON.parse(localStorage.getItem('uml_user'));

  //   if (otSession.current) {
  //     const session = otSession.current.sessionHelper.session;
  //     // const user = JSON
  //     session.signal({
  //       type: "user-join",
  //       data: user.user.fullName, // You can replace this with the user's actual name
  //     });
  //   }
  // };

  // componentDidMount() {
  //   this.sendUserJoinSignal(); // Send the "user-join" signal when the user joins the call
  // }


  render() {
    console.log("this.props.userDetails", this.props.userDetails)
    console.log("window.location", location)

    return (
      <>
        {/* <Row> */}
        {this.state.error ? (
          <div id="error">{this.state.error}</div>
        ) : null}
        {/* <Col lg={24}> */}
        <div className="publisher">
          <OTPublisher
            properties={{
              publishAudio: this.state.audio,
              publishVideo: this.state.video,
              videoSource:
                this.state.videoSource === "screen" ? "screen" : undefined,
              name: this.props?.publisherName
            }}
            onError={this.onError}
            className="publisher2"
          />
          {/* <CheckBox
          label="Publish Audio"
          initialChecked={this.state.audio}
          onChange={this.setAudio}
        /> */}
          <div className={`button-div justify-content-between px-2 ${this.state.video ? "text-dark" : "text-white"}`} style={{
            top: this.state.video ? "12px" : "6%",
            background: this.state.video ? "" : "none"
          }}>

            <img style={{
              width: this.state.video ? "150px" : "200px",
            }} src={IMAGES.UML_LOGO} />
            {this.state.video &&
              <>
                <div className="badge bg-danger h-auto w-auto text-white px-2 py-0 rounded"><BsDot width={10} /> Live</div>
                <p className="mb-0 text-white mb-0"><b>{JSON.parse(window.localStorage.getItem("uml_user"))?.user?.fullName}</b></p>
              </>}
          </div>
          {!this.state.video &&
            <h3 className={`button-div justify-content-center mb-0 ${this.state.video ? "text-dark" : "text-white"}`} style={{
              bottom: "10%",
              background: "none"
            }}>
              {JSON.parse(window.localStorage.getItem("uml_user"))?.user?.fullName}
            </h3>
          }

          <div className={`button-div justify-content-center mb-0 ${this.state.video ? "text-dark" : "text-white"}`} style={{
            bottom: "12px",
            background: "none"
          }}>
            {/* <div className=""> */}
            <div className="cursor-pointer">
              <MdCall
                size={25}
                color="#fff"
                onClick={() => this.discounthandlerEnd()}
              />
            </div>
            <div className="bg-dark mx-1">
              {this.state.audio === true ? (
                <AiOutlineAudio
                  onClick={this.setAudio}
                  size={25}
                  className="cursor-pointer text-white"
                />
              ) : (
                <AiOutlineAudioMuted
                  onClick={this.setAudio}
                  size={25}
                  className="cursor-pointer text-white"
                />
              )}
            </div>
            <div className="bg-dark">
              {this.state.video === true ? (
                <BsFillCameraVideoFill
                  onClick={this.setVideo}
                  size={20}
                  className="cursor-pointer text-white"
                />
              ) : (
                <BsFillCameraVideoOffFill
                  onClick={this.setVideo}
                  size={20}
                  className="cursor-pointer text-white"
                />
              )}
            </div>
            <div className="bg-dark mx-1">
              {this.props.showChat ? (
                <RiChat4Fill
                  onClick={() => this.props.setShowChat(!this.props.showChat)}
                  size={20}
                  className="cursor-pointer text-white"
                />
              ) : (
                <RiChatOffFill
                  onClick={() => this.props.setShowChat(!this.props.showChat)}
                  size={20}
                  className="cursor-pointer text-white"
                />
              )}
            </div>
            {/* <CheckBox
            label="Publish Audio"
            initialChecked={this.state.audio}
            onChange={this.setAudio}
          /> */}
          </div>
          {/* </div> */}
        </div>
        {/* </Col> */}
        {/* <Col lg={12}>
            <Row gutter={24}>
              <Col lg={12}>
              </Col>
              <Col lg={12}>
              </Col>
            </Row>
            <OTPublisher
              properties={{
                publishAudio: this.state.audio,
                publishVideo: this.state.video,
                videoSource:
                  this.state.videoSource === "screen" ? "screen" : undefined,
              }}
              onError={this.onError}
              className="subscriber"
            />
          </Col> */}
        {/* </Row> */}
      </>
    );
  }
}
export default Publisher;
