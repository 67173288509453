/* eslint-disable no-duplicate-case  */
import { useMemo } from "react";
import usePersist from "./usePersist";

export const RoleEnum = {
  SUPER_ADMIN: 1,
  ADMIN: 2,
  CLINIC: 3,
  DOCTOR: 4,
  ACCOUNT_EXECUTIVE: 5,
  DRIVER: 6,
  BILLING: 7,
  PAYROLL: 8,
  ELIGIBILITY: 9,
  IT_SUPER_ADMIN: 10,
  NURSE_PRACTITIONER: 11,
  COSTUMER_SUCCESS: 13,
};

export const PermissionEnum = {
  // Chat
  OPEN_INDIVIDUAL_CHATS: "open_individual_chats",
  OPEN_GROUP_CHATS: "open_group_chats",
  LIST_CHATS: "list_chats",
  CHANGE_NAME_CHATS: "change_name_chats",

  // Ticket
  OPEN_TICKETS: "open_tickets",
  LIST_TICKETS: "list_tickets",
  CHANGE_STATUS_TICKETS: "change_status_tickets",
  CHANGE_STATUS_ASSIGNED_TICKETS: "change_status_assigned_tickets",

  // MEMBERS
  LIST_MEMBERS: "list_members",
  ADD_MEMBERS: "add_members",
  REMOVE_MEMBERS: "remove_members",

  // Groups
  LIST_GROUPS: "list_groups",
  CREATE_GROUPS: "create_groups",
  DELETE_GROUPS: "delete_groups",
  EDIT_GROUP_NAME: "edit_group_name",
  CHANGE_STATUS_GROUPS: "change_status_groups",
  CHANGE_STATUS_ASSIGNED_GROUPS: "change_status_assigned_groups",

  // Audio
  AUDIO_CALLS: "audio_calls",
};

const getUserPermissions = (roles) => {
  let permissions = [];

  roles.forEach((role) => {
    // eslint-disable-next-line default-case
    switch (role.id) {
      case RoleEnum.SUPER_ADMIN:
      case RoleEnum.ADMIN:
      case RoleEnum.IT_SUPER_ADMIN:
        permissions = [
          ...permissions,
          ...Object.values(PermissionEnum).filter(
            (permission) =>
              permission !== PermissionEnum.OPEN_INDIVIDUAL_CHATS &&
              permission !== PermissionEnum.OPEN_GROUP_CHATS
          ),
        ];
        break;
      case RoleEnum.COSTUMER_SUCCESS:
        permissions = [...permissions, PermissionEnum.ADD_MEMBERS];

      // eslint-disable-next-line no-fallthrough
      case RoleEnum.DOCTOR:
      case RoleEnum.DRIVER:
      case RoleEnum.BILLING:
      case RoleEnum.PAYROLL:
      case RoleEnum.CLINIC:
      case RoleEnum.ELIGIBILITY:
      case RoleEnum.NURSE_PRACTITIONER:
      case RoleEnum.COSTUMER_SUCCESS:
        permissions = [
          ...permissions,
          PermissionEnum.LIST_CHATS,
          PermissionEnum.LIST_GROUPS,
          PermissionEnum.LIST_TICKETS,
          PermissionEnum.LIST_MEMBERS,
        ];

      // eslint-disable-next-line no-fallthrough
      case RoleEnum.DOCTOR:
      case RoleEnum.PAYROLL:
      case RoleEnum.CLINIC:
      case RoleEnum.ELIGIBILITY:
      case RoleEnum.NURSE_PRACTITIONER:
      case RoleEnum.COSTUMER_SUCCESS:
        permissions = [
          ...permissions,
          PermissionEnum.CHANGE_STATUS_ASSIGNED_TICKETS,
          PermissionEnum.CHANGE_STATUS_ASSIGNED_GROUPS,
          // PermissionEnum.ADD_MEMBERS,
        ];

        break;
      case RoleEnum.ACCOUNT_EXECUTIVE:
        permissions = [
          ...permissions,
          PermissionEnum.LIST_CHATS,
          PermissionEnum.OPEN_INDIVIDUAL_CHATS,
          PermissionEnum.OPEN_GROUP_CHATS,
          PermissionEnum.OPEN_TICKETS,
          PermissionEnum.LIST_MEMBERS,
        ];
        break;
    }
  });

  return [...new Set(permissions)];
};

const useAuth = () => {
  // const [userPermissions, setUserPermissions] = useState([]);
  const [umlUser] = usePersist("uml_user");
  const { user, token } = umlUser;

  const userPermissions = useMemo(() => {
    if (!user?.roles) return [];

    return getUserPermissions(user?.roles);
  }, []);

  const userCan = (permission) => userPermissions.includes(permission);

  const isAdmin = useMemo(
    () =>
      !!user?.roles?.find(
        (item) =>
          item.name.toLowerCase() === "admin" ||
          item.name.toLowerCase() === "super admin"
      ),
    []
  );

  const isAE = useMemo(
    () =>
      !!user?.roles?.find(
        (item) => item.name.toLowerCase() === "account executive"
      ),
    []
  );

  return { user, isAdmin, isAE, userCan, token };
};

export default useAuth;
