import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Toaster from "../../../components/Toaster";
import Conversations from "./conversation.services";

export const getMessagesList = createAsyncThunk(
  "conversations/all",
  async (thunkAPI) => {
    try {
      const response = await Conversations.getMessages();
      return response;
    } catch (error) {
      Toaster({ type: "error", message: error.message });
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getMessagesListByChatId = createAsyncThunk(
  "conversations/id",
  async ({ chatId }, thunkAPI) => {
    try {
      const response = await Conversations.getMessagesByChatId(chatId);

      return response.data;
    } catch (error) {
      Toaster({ type: "error", message: error.message });
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const sendMessage = createAsyncThunk(
  "conversations/id/send",
  async ({ chatId, payload, moveToNext }, thunkAPI) => {
    try {
      const response = await Conversations.sendMessage(chatId, payload);

      if (moveToNext && response.status === 200) {
        moveToNext(response.data);
      }

      return response.data;
    } catch (error) {
      Toaster({ type: "error", message: error.message });
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const readMessages = createAsyncThunk(
  "conversations/read/id",
  async ({ chatId }, thunkAPI) => {
    try {
      const response = await Conversations.readMessage(chatId);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Toaster({ type: "error", message: error.message });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteMessageById = createAsyncThunk(
  "conversations/del/id",
  async ({ messageId, notifyToaster }, thunkAPI) => {
    try {
      const response = await Conversations.deleteMessage(messageId);
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const AddCallingUsers = createAsyncThunk(
  "chat/add-calling-users",
  async ({ data, notifyToaster }, thunkAPI) => {
    try {
      const response = await Conversations.addCallingUsers(data);
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const RemoveCallingUsers = createAsyncThunk(
  "chat/remove-calling-users",
  async ({ data, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await Conversations.removeCallingUsers(data);
      if (moveToNext) moveToNext();
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const UpdateCallingStatus = createAsyncThunk(
  "chat/update-calling-status",
  async ({ data, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await Conversations.updateCallingStatus(data);
      if (moveToNext) {
        moveToNext(response.data);
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const UpdateTitle = createAsyncThunk(
  "chat/update-title",
  async ({ chatId, title }, thunkAPI) => {
    try {
      const response = await Conversations.updateTitle(chatId, title);

      return response.data;
    } catch (error) {
      Toaster({ type: "error", message: error.message });
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState = {
  messages: [],
  isLoading: false,
  isError: false,
  errorMessage: null,

  addMessageLoading: false,
  addMessageError: false,
  addMessageErrorMessage: null,

  readMessageLoading: false,
  readMessageError: false,
  readMessageErrorMessage: null,
};

export const conversationSlice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    reset() {
      return {
        messages: [],
        isLoading: false,
        isError: false,
        errorMessage: null,

        addMessageLoading: false,
        addMessageError: false,
        addMessageErrorMessage: null,

        readMessageLoading: false,
        readMessageError: false,
        readMessageErrorMessage: null,
      };
    },
    receiveNewMessage: (state, { payload }) => {
      const { newMessage } = payload;
      state.messages.push(newMessage);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMessagesListByChatId.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMessagesListByChatId.fulfilled, (state, action) => {
      state.isLoading = false;
      state.messages = action.payload;
    });
    builder.addCase(getMessagesListByChatId.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
      state.messages = [];
    });

    builder.addCase(sendMessage.pending, (state) => {
      state.addMessageLoading = true;
    });
    builder.addCase(sendMessage.fulfilled, (state, action) => {
      state.addMessageError = false;
      state.messages.push(action.payload);
    });
    builder.addCase(sendMessage.rejected, (state, action) => {
      state.addMessageLoading = false;
      state.errorMessage = true;
      state.addMessageErrorMessage = action.payload;
    });
    builder.addCase(readMessages.pending, (state) => {
      state.readMessageLoading = true;
    });
    builder.addCase(readMessages.fulfilled, (state) => {
      state.readMessageLoading = false;
    });
    builder.addCase(readMessages.rejected, (state, action) => {
      state.readMessageLoading = false;
      state.readMessageError = true;
      state.readMessageErrorMessage = action.payload;
    });
  },
});

export const conversationActions = conversationSlice.actions;

export default conversationSlice.reducer;
