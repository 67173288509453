/* eslint-disable */
import IMAGES from "../../assets/images";

function NoPageFound() {
  return <></>;

  return (
    <div className="not-found-container">
      <h1>404 - Page Not Found</h1>
      <p>Oops! The page you are looking for does not exist.</p>
      <img
        className="not-found-image"
        src={IMAGES.NOTFOUND}
        alt="404 Illustration"
        width="600"
        height="600"
      />
    </div>
  );
}

export default NoPageFound;
