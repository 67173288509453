/* eslint-disable */

import React from "react";
import { OTSubscriber } from "opentok-react";

class Subscriber extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef()
    this.state = {
      error: null,
      audio: true,
      video: true,
      callerName: ""
    };
  }

  setAudio = (audio) => {
    this.setState({ audio });
  };

  setVideo = (video) => {
    this.setState({ video });
  };

  onError = (err) => {
    this.setState({ error: `Failed to subscribe: ${err.message}` });
  };

  // Attach event handler to receive signals
  // componentDidMount() {
  //   if (otSession.current) {
  //     const session = otSession.current.sessionHelper.session;
  //     // Attach a handler for the "signal" event
  //     session.on("user-join", (event) => {
  //       debugger
  //       // Check the signal type (e.g., "user-join")
  //       if (event.type === "user-join") {
  //         // Update the caller's name in the component's state
  //         this.setState({ callerName: event.data });
  //       }
  //     });
  //   }
  //   // Access the session from the otSession ref
  // }
  componentDidMount() {
    // console.log("componentDidMount", this.inputRef)
    console.log("componentDidMount2", this.inputRef?.current?.context?.stream?.name)
    this.setState({ callerName: this.inputRef?.current?.context?.stream?.name });

  }
  render() {
    console.log("this.props.userDetails", this.props.userDetails)
    console.log("window.location", window.location)
    console.log("otSession subscriber", this.props.otSession);
    console.log("callername:", this.state.callerName);
    console.log("session sub:", this.props.otSession)
    console.log("input ref", this.inputRef)
    console.log("name....", this.inputRef)

    return (
      <div className="subscriber">
        {this.state.error ? <div id="error">{this.state.error}</div> : null}
        <OTSubscriber
          properties={{
            subscribeToAudio: this.state.audio,
            subscribeToVideo: this.state.video,
          }}
          ref={this.inputRef}
          onError={this.onError}
          className="subscriber-2"
        />

        <p className="text-white button-div mb-0"><b>{this.state.callerName || "NA"}</b></p>

      </div>
    );
  }
}
export default Subscriber;
