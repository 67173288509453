/* eslint-disable */
import { Card, Input } from "antd";
import { Formik } from "formik";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { IoIosSend } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
  getMessagesListByChatId,
  sendMessage,
} from "../../../app/features/conversations/conversation.slice";
import Toaster from "../../../components/Toaster";
import SocketContext from "../../../context/socketContext";
import HtmlConverter, { useQuery } from "../../../utils/helpers";

function CallScreenChat() {
  const { state } = useLocation();
  const user = JSON.parse(localStorage.getItem("uml_user"));
  const [fileList, setFileList] = useState([]);
  const navigate = useNavigate();
  const { socket } = useContext(SocketContext);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  console.log("test socket: ", socket);

  const dispatch = useDispatch();
  const { messages } = useSelector((item) => item.conversation);
  const query = useQuery();
  const chatId = query.get("chatId");
  const groupId = query.get("groupId");

  const [msgs, setMsgs] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
  };

  useEffect(() => {
    if (chatId) {
      dispatch(getMessagesListByChatId({ chatId: chatId }));
    }
  }, [chatId]);

  useEffect(() => {
    setMsgs(messages);
  }, [messages]);

  const schema = Yup.object().shape({
    message: Yup.string(),
    // .min(2, "Too Short!")
    // .required("This field is required"),
  });
  console.log("socket call screen: ", socket);

  useEffect(() => {
    if (!socket) return;
    const receiveMessageHandler = (data) => {
      // if (data.username !== user.user.fullName) {
      let finalData = { ...data, senderName: data.username };
      setMsgs((prev) => [...prev, finalData]);
      // }
    };

    // Attach the event listener
    socket.on("receiveMessage", receiveMessageHandler);

    // Clean up by removing the event listener when the component unmounts
    return () => {
      socket.off("receiveMessage", receiveMessageHandler);
    };
  }, [socket]);

  const conversation = msgs?.map((item) => {
    if (item) {
      const { message: text } = item;
      const searchIndex = text.toLowerCase().indexOf(search.toLowerCase());
      if (searchIndex !== -1) {
        const beforeMatch = text.substring(0, searchIndex);
        const match = text.substring(searchIndex, searchIndex + search.length);
        const afterMatch = text.substring(searchIndex + search.length);
        const highlightedMessage = `${beforeMatch}<span class="highlight">${match}</span>${afterMatch}`;
        return {
          ...item,
          highlightedMessage,
        };
      }
      return item;
    }
  });

  //admin

  const messagesContainerRef = useRef(null);

  // Function to scroll to the bottom of the messages container
  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTo({
        top: messagesContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [conversation]);

  function onMessageSentSuccess(data) {
    // setMsgs((prev) => [...prev, data]);

    try {
      socket.emit("sendMessage", {
        userId: user?.user?.id,
        userName: user?.user?.fullName,
        chatId,
        message: data.message,
        room: +groupId,
        roomMembers: state.callingDetails?.roomMembers.filter(
          (item) => item.id !== user?.user?.id
        ),
      });
    } catch (e) {
      Toaster({ type: "error", message: e.message });
      console.log("socket error: ", e.message);
    }
  }

  return (
    <>
      {/* {contextHolder} */}
      <div className={`chat-parent h-100 rounded px-2 pt-2`}>
        {chatId ? (
          <div className="chat-card w-100 h-100">
            <div
              className="messages-container p-0"
              style={{ height: "calc(100vh - 85px)", overflow: "auto" }}
              ref={messagesContainerRef}
            >
              <ul className="px-0">
                {conversation?.map((item) => (
                  <li
                    key={item?.id}
                    className={`mt-3 ${
                      item?.sentBy === user?.user?.id
                        ? "message-sent"
                        : "message-received"
                    }`}
                  >
                    {item?.sentBy === user?.user?.id ? (
                      <></>
                    ) : (
                      <div
                        style={{ width: "35px", height: "35px" }}
                        className="rounded-circle mr-2"
                      >
                        <img
                          src={`https://ui-avatars.com/api/?name=${item?.senderName}&background=17406d&color=fff`}
                          className="w-100 rounded-circle"
                        />
                      </div>
                    )}
                    <div className="mw-70">
                      {item?.sentBy !== user?.user?.id ? (
                        <div className="d-flex align-items-center justify-content-between">
                          <small className="text-black fw-bold mr-3">
                            {item?.senderName ?? "N/A"}
                          </small>{" "}
                          <small className="m-0">
                            {moment(item?.created_at).format("hh:mm a")}
                          </small>
                        </div>
                      ) : (
                        <></>
                      )}
                      <Card className="m-0">
                        <span className="text-black">
                          <HtmlConverter
                            message={item.highlightedMessage || item.message}
                          />
                        </span>
                        {item?.sentBy !== user?.user?.id ? (
                          <></>
                        ) : (
                          <span>
                            <div>
                              {moment(item?.created_at).format("hh:mm a")}
                            </div>
                          </span>
                        )}
                      </Card>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <Formik
              validationSchema={schema}
              initialValues={{ message: "" }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                if (chatId) {
                  dispatch(
                    sendMessage({
                      chatId: chatId,
                      payload: { message: values.message },
                      moveToNext: onMessageSentSuccess,
                    })
                  );
                  resetForm();
                  setSubmitting(false);
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="send-message-wrapper p-0">
                    <div className="send-message-inner-wrapper w-100 d-flex align-items-center">
                      <Input
                        className="pl-3 send-message mr-3"
                        placeholder="Type here..."
                        type="text"
                        name="message"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.message}
                        // required={!!errors?.message}
                      />

                      {/* <div
                        className={`upload-icon cursor-pointer ${
                          fileList?.length > 0 ? "hide-upload-icon" : ""
                        }`}
                      >
                        <Upload {...props}>
                          <IoMdAttach size={22} />
                        </Upload>
                      </div> */}
                    </div>
                    <button type="submit" className="border-0 bg-transparent">
                      <div className="send-icon-bg cursor-pointer">
                        <IoIosSend className="text-white" size={28} />
                      </div>
                    </button>
                  </div>
                  {errors.message && touched.message ? (
                    <span className="my-2 px-2" style={{ color: "red" }}>
                      {errors.message}
                    </span>
                  ) : null}
                </form>
              )}
            </Formik>
          </div>
        ) : (
          <div className="no-chat d-flex align-items-center justify-content-center">
            <p>Select chat to begin here....</p>
          </div>
        )}
      </div>
    </>
  );
}

export default CallScreenChat;

// CallScreenChat.propTypes = {
//   id: PropTypes.number.isRequired,
// };
