/* eslint-disable */

import { useEffect } from "react";
import VideoCall from "../../../components/videoCall";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Col, Row } from "antd";
import CallScreenChat from "./CallScreenChat";
import { useState } from "react";

function VideoCalling() {
  const location = useLocation();
  const authUser = JSON.parse(localStorage.getItem("uml_user"));
  const chats = useSelector((state) => state.chat);
  const { callingDetails, name } = location?.state;
  console.log("calling", callingDetails);

  const admin = authUser?.user?.roles?.filter(
    (item) =>
      item.name.toLowerCase() === "admin" ||
      item.name.toLowerCase() === "super admin"
  );

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showChat, setShowChat] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Row gutter={24}>
      <Col sm={24} lg={showChat ? 18 : 24} className="bg-white">
        <div className="video-call">
          {console.log("Publisher=>>>>")}
          {!(chats?.videoDetails || callingDetails) ? (
            <div>Laoding.....</div>
          ) : (
            <VideoCall
              apiKey="47780131"
              showChat={showChat}
              setShowChat={setShowChat}
              sessionId={
                chats?.videoDetails?.sessionId ||
                callingDetails?.sessionId ||
                ""
              }
              token={chats?.videoDetails?.token || callingDetails?.token || ""}
              userDetails={callingDetails || chats?.videoDetails}
              name={name}
            />
          )}
        </div>
      </Col>
      <Col sm={showChat === false ? 0 : showChat && windowWidth < 993 ? 24 : 6}>
        <CallScreenChat />
      </Col>
    </Row>
  );
}

export default VideoCalling;
