/* eslint-disable import/no-cycle */

import React from "react";
import LIVE_CHAT from "./LiveChat";
import ChatId from "./Chat/Chat";

const TICKET = React.lazy(() => import("./Tickets/TicketList"));
const CHAT = React.lazy(() => import("./Chat/ChatList"));
const GROUP = React.lazy(() => import("./Groups/GroupList"));
const ACCOUNT_SETTINGS = React.lazy(() => import("./AccountSettings"));

const CLIENT_PAGES = {
  TICKET,
  CHAT,
  CHAT_ID: ChatId,
  GROUP,
  ACCOUNT_SETTINGS,
  LIVE_CHAT,
};
export default CLIENT_PAGES;
