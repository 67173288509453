import Api from "../../../utils/api";

async function getMembers(roleType, isOnline) {
  let response;
  if (isOnline !== undefined) {
    response = await Api.get(`users/${roleType}/members?isOnline=${isOnline}`);
  } else {
    response = await Api.get(`users/${roleType}/members`);
  }
  return response.data;
}

const Members = { getMembers };
export default Members;
