/* eslint-disable */

import { Avatar, notification } from "antd";
import { OTSession, OTStreams, preloadScript } from "opentok-react";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import Publisher from "./components/Publisher";
import Subscriber from "./components/Subscriber";
import "./index.css";
// import ring from "../../../src/Audio/ringBell.mp3";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import Swal from "sweetalert2";
import {
  AddCallingUsers,
  RemoveCallingUsers,
} from "../../app/features/conversations/conversation.slice";
import IMAGES from "../../assets/images";
import SocketContext from "../../context/socketContext";
import useAuth from "../../hooks/useAuth";
import { useQuery } from "../../utils/helpers";
import Toaster from "../Toaster";
// import { IncomingCall } from "../../Navigation/Routes";

function App({
  apiKey,
  sessionId,
  token,
  userDetails,
  name,
  showChat,
  setShowChat,
}) {
  const { user } = useAuth();
  const [error, setError] = useState(null);
  const { socket } = useContext(SocketContext);

  const location = useLocation();
  const state = location?.state;

  const query = useQuery();
  const chatId = query.get("chatId");
  const groupId = query.get("groupId");
  const dispatch = useDispatch();

  const [connected, setConnected] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  // console.log("location.pathname");
  const otSession = useRef(null);

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (name, avatar, message) => {
    console.log("end call");
    //
    api.open({
      message: (
        <div className="d-flex align-items-center">
          <Avatar src={avatar || IMAGES.NO_USER_GREY} className="me-2" />
          <h3>{name}</h3>
        </div>
      ),
      description: message,
      duration: 2,
      onClose: () => {
        // Use the onClose callback to navigate after the notification closes
        navigate(-1); // Navigate to the previous page
      },
    });
    // navigate(-1);
  };

  const sessionEvents = {
    sessionConnected: () => {
      // callRining();
    },
    sessionDisconnected: () => {
      setConnected(false);

      console.log("disconnected");
      // navigate(location.pathname);
    },
    streamCreated: (e) => {
      console.log("stream Created: ", e);
      // navigate(location.pathname);
    },
  };

  const callRining = (call) => {
    setIsModalOpen(true);
  };

  // function UserCalling() {
  //   IncomingCall(sessionEvents);
  // }

  const onSignalRecieve = (signal) => {
    console.log("onSignalReceive => ", JSON.parse(signal.data));
    // based on signal data type you can do use switch or conditional statements
  };

  const onError = (err) => {
    setError(`Failed to connect: ${err.message}`);
  };

  useEffect(() => {
    if (chatId && user.id) {
      dispatch(
        AddCallingUsers({
          data: {
            chatId: chatId,
            userId: user.id,
          },
          moveToNext: async () => {},
        })
      );
    }
    return () => {
      // dispatch(
      //   RemoveCallingUsers({
      //     data: {
      //       chatId: chatId,
      //       userId: user.id,
      //     },
      //     moveToNext: async () => {},
      //   })
      // );
    };
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("uml_user"));

    // otSession.current.sessionHelper.session.signal(
    //   {
    //     type: "user-join",
    //     data: user.fullName,
    //   },
    //   (error) => {
    //     if (error) {
    //       console.error("Error sending signal:", error);
    //     } else {
    //       debugssger;
    //       console.log("signal sent");
    //     }
    //   }
    // );
  }, []);

  useEffect(() => {
    window.addEventListener(
      "popstate",
      function (event) {
        // The popstate event is fired each time when the current history entry changes.

        // var r = confirm("You pressed a Back button! Are you sure?!");

        // if (r == true) {
        //     // Call Back button programmatically as per user confirmation.
        //     history.back();
        //     // Uncomment below line to redirect to the previous page instead.
        //     // window.location = document.referrer // Note: IE11 is not supporting this.
        // } else {
        //     // Stay on the current page.
        //     history.pushState(null, null, window.location.pathname);
        // }

        // history.pushState(null, null, window.location.pathname);

        Swal.fire({
          title: "Are you sure? You are disconnecting from call!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#40BEEE",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, disconnect it!",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(-1);
            dispatch(
              RemoveCallingUsers({
                data: {
                  chatId: chatId,
                  userId: user.id,
                },
                moveToNext: async () => {
                  Toaster({ type: "error", message: response?.message });
                  navigate("/chat");
                },
              })
            );
          } else {
            navigate(
              {
                pathname: "/audio-call",
                search: `?chatId=${chatId}&groupId=${groupId}`,
              },
              {
                state: {
                  callingDetails: state.callingDetails,
                  name,
                },
              }
            );
          }
        });
      },
      false
    );
  }, []);

  const endSession = async () => {
    dispatch(
      RemoveCallingUsers({
        data: {
          chatId: chatId,
          userId: user.id,
        },
        moveToNext: () => {
          socket?.emit("disconnectCall", {
            aeId: userDetails.aeId,
            roomMembers: userDetails.roomMembers,
            name: userDetails.name,
            avatar: userDetails.avatar,
            message: `Call cancelled from ${userDetails.name}`,
            chatId,
          });
          navigate("/chat");
          otSession.current.sessionHelper.session.destroy();
        },
      })
    );
  };

  console.log("otsession: ", otSession);

  const showModal = () => {
    setIsModalOpen(true);
    setConnected(false);
  };
  // const handleOk = () => {
  //   setIsModalOpen(false);
  //   setConnected(true);
  //   stop();
  // };

  // const handleCancel = () => {
  //   setIsModalOpen(false);
  //   navigate(PATH.CHAT);
  // };

  // const soundUrl =
  //   "https://www.zapsplat.com/wp-content/uploads/2015/sound-effects-84577/zapsplat_multimedia_ringtone_mallets_marimba_smartphone_001_84890.mp3";

  // const [play, { stop }] = useSound(soundUrl, { volume: 0.5 });

  // const [isHovering, setIsHovering] = React.useState(false);

  // useEffect(() => {
  //   //
  //   showModal(true);
  //   play();

  //   // Stop the sound when the component is unmounted
  //   return () => {
  //     stop();
  //   };
  // }, [play, stop]);

  // const audioRef = useRef(null);

  // useEffect(() => {
  //   audioRef.current?.play();
  // }, []);

  function notAttendCall() {
    setIsModalOpen(false);
    setConnected(false);
    // navigate(location.pathname);
  }

  function attendCall() {
    // setIsModalOpen(false);
    setConnected(true);
    stop();
  }

  // Toaster({ type: "success", message: "Logged in successfully" });
  useEffect(() => {
    BeatLoader;
  });

  const [rejectCall, setRejectCall] = useState(null);
  useEffect(() => {
    if (!socket) return;

    socket.on("callNotification", ({ name, avatar, callerId, message }) => {
      setRejectCall({
        callerId,
        name,
        avatar,
        message,
      });

      openNotification(name, avatar, message);
      // Toaster("Call ended!");
    });
  }, [socket]);

  console.log("otSession", otSession);

  return (
    <>
      {contextHolder}
      <OTSession
        ref={otSession}
        apiKey={apiKey}
        sessionId={sessionId}
        token={token}
        eventHandlers={sessionEvents}
        onError={onError}
        onStreamsUpdated={() => {}}
        className="video-call1"
      >
        {error ? <div id="error">{error}</div> : null}
        <Publisher
          endSession={endSession}
          userDetails={userDetails}
          name={name}
          publisherName={user?.fullName}
          showChat={showChat}
          otSession={otSession}
          setShowChat={setShowChat}
        />
        <OTStreams>
          <Subscriber
            endSession={endSession}
            userDetails={userDetails}
            name={name}
            otSession={otSession}
          />
        </OTStreams>
      </OTSession>
    </>
  );
}

App.propTypes = {
  apiKey: PropTypes.string.isRequired,
  sessionId: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};

export default preloadScript(App);
