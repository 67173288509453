/* eslint-disable import/no-named-as-default */
/* eslint-disable no-unused-vars */
/* eslint-disable */
import { useParams } from "react-router";
import Chat from "../../../components/pages/Chat/Chat";

function index() {
  const { chatId } = useParams();

  return <Chat chatId={chatId} />;
}

export default index;
