import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ChatGroups from "./chatGroup.service";
import Toaster from "../../../components/Toaster";

export const getChatDepartments = createAsyncThunk(
  "chatDepartments/all",
  async (thunkAPI) => {
    try {
      const data = await ChatGroups.chatDepartments();
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Toaster({ type: "error", message });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getGroups = createAsyncThunk("groups/all", async (thunkAPI) => {
  try {
    const response = await ChatGroups.getGroups();
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    Toaster({ type: "error", message });
    return thunkAPI.rejectWithValue(message);
  }
});

export const getChatGroups = createAsyncThunk(
  "chatGroup/all",
  async (
    {
      page = 1,
      item = 10,
      title = null,
      startDate = null,
      endDate = null,
      status = "",
    },
    thunkAPI
  ) => {
    try {
      const params = { page, item, title, startDate, endDate, status };
      const response = await ChatGroups.getChatGroupList(params);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Toaster({ type: "error", message });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getGroupByIdAction = createAsyncThunk(
  "chatGroup/getGroupById",
  async ({ groupId }, thunkAPI) => {
    try {
      const response = await ChatGroups.getGroupById(groupId);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Toaster({ type: "error", message });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addChatGroup = createAsyncThunk(
  "chatGroup/add",
  async ({ payload, onFinish }, thunkAPI) => {
    const { reason, title, ...rest } = payload;
    try {
      const response = await ChatGroups.createChatGroup(rest);

      if (response.status === 201) {
        onFinish({ reason, title, ...response.data });
        // Toaster({ type: "success", message: "Record added successfully" });
      }
      return response?.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Toaster({ type: "error", message });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addMember = createAsyncThunk(
  "chatGroup-add/members",
  async ({ onSuccess, groupId, payload }, thunkAPI) => {
    try {
      const response = await ChatGroups.addMemberToChatGroup(groupId, payload);
      if (response.status === 200) {
        Toaster({ type: "success", message: "Record added successfully" });
        onSuccess();
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Toaster({ type: "error", message });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const removeMember = createAsyncThunk(
  "chatGroup-remove/members",
  async ({ groupId, payload }, thunkAPI) => {
    try {
      const response = await ChatGroups.removeMemberFromChatGroup(
        groupId,
        payload
      );
      if (response.status === 200) {
        Toaster({ type: "success", message: "Record removed successfully" });
      }
      return response?.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Toaster({ type: "error", message });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteGroup = createAsyncThunk(
  "delete-chatGroup",
  async ({ groupId }, thunkAPI) => {
    try {
      const response = await ChatGroups.destroyChatGroup(groupId);
      if (response.status === 200) {
        Toaster({ type: "success", message: "Group removed successfully!" });
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Toaster({ type: "error", message });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateGroupStatus = createAsyncThunk(
  "chatGroup-updateStatus/members",
  async ({ groupId, payload }, thunkAPI) => {
    try {
      const response = await ChatGroups.updateChatGroupStatus(groupId, payload);
      if (response.status === 200) {
        Toaster({ type: "success", message: "Record added successfully" });
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Toaster({ type: "error", message });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateGroup = createAsyncThunk(
  "chatGroup-update/members",
  async ({ groupId, payload }, thunkAPI) => {
    try {
      const response = await ChatGroups.updateChatGroup(groupId, payload);
      if (response.status === 200) {
        Toaster({ type: "success", message: "Record updated successfully" });
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Toaster({ type: "error", message });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState = {
  groups: [],
  isGroupsLoading: false,
  isGroupsError: false,
  isGroupsErrorMessage: null,

  chatGroups: [],
  isGroupLoading: false,
  isGroupSuccess: false,
  isGroupError: false,
  isGroupErrorMessage: null,

  isAddGroupLoading: false,
  isAddGroupSuccess: false,
  isAddGroupError: false,
  isAddGroupErrorMessage: null,

  isAddMemberLoading: false,
  isAddMemberSuccess: false,
  isAddMemberError: false,
  isAddMemberErrorMessage: null,

  isRemoveMemberLoading: false,
  isRemoveMemberSuccess: false,
  isRemoveMemberError: false,
  isRemoveMemberErrorMessage: null,

  isDeleteGroupLoading: false,
  isDeleteGroupSuccess: false,
  isDeleteGroupError: false,
  isDeleteGroupErrorMessage: null,

  isGroupUpdating: false,
  isGroupUpdateSuccess: false,
  isGroupUpdateError: false,

  departments: [],
  isDepartmentsLoading: false,
  isDepartmentsSuccess: false,
  isDepartmentsError: false,
  isDepartmentsErrorMessage: null,

  isGroupStatusUpdating: false,
  isGroupStatusSuccess: false,
  isGroupStatusError: false,

  singleGroup: {},
  isGroupByIdLoading: false,
  isGroupByIdSuccess: false,
  isGroupByIdError: false,
  isGroupByIdErrorMessage: false,
};

export const chatGroupSlice = createSlice({
  name: "chatGroup",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addChatGroup.pending, (state) => {
      state.isAddGroupLoading = true;
    });
    builder.addCase(addChatGroup.fulfilled, (state) => {
      state.isAddGroupLoading = false;
      state.isAddGroupSuccess = true;
      // state.chatGroups.data.push(action.payload);
    });
    builder.addCase(addChatGroup.rejected, (state) => {
      state.isAddGroupLoading = false;
      state.isAddGroupSuccess = false;
      state.isAddGroupError = true;
    });
    builder.addCase(getChatGroups.pending, (state) => {
      state.isGroupLoading = true;
    });
    builder.addCase(getChatGroups.fulfilled, (state, action) => {
      state.isGroupLoading = false;
      state.isGroupSuccess = true;
      state.chatGroups = action.payload;
    });
    builder.addCase(getChatGroups.rejected, (state, payload) => {
      state.isGroupSuccess = false;
      state.isGroupError = true;
      state.isGroupErrorMessage = payload.data;
      state.chatGroups = [];
    });
    builder.addCase(addMember.pending, (state) => {
      state.isAddMemberLoading = true;
    });
    builder.addCase(addMember.fulfilled, (state) => {
      state.isAddMemberLoading = false;
      state.isAddMemberSuccess = true;
    });
    builder.addCase(addMember.rejected, (state, action) => {
      state.isAddMemberLoading = false;
      state.isAddMemberSuccess = false;
      state.isAddMemberError = true;
      state.isAddMemberErrorMessage = action.payload;
    });
    builder.addCase(removeMember.pending, (state) => {
      state.isRemoveMemberLoading = true;
    });
    builder.addCase(removeMember.fulfilled, (state) => {
      state.isRemoveMemberLoading = false;
      state.isRemoveMemberSuccess = true;
    });
    builder.addCase(removeMember.rejected, (state, action) => {
      state.isRemoveMemberLoading = false;
      state.isRemoveMemberSuccess = false;
      state.isRemoveMemberError = true;
      state.isRemoveMemberErrorMessage = action.payload;
    });
    builder.addCase(deleteGroup.pending, (state) => {
      state.isDeleteGroupLoading = true;
    });
    builder.addCase(deleteGroup.fulfilled, (state) => {
      state.isDeleteGroupLoading = false;
      state.isDeleteGroupSuccess = true;
    });
    builder.addCase(deleteGroup.rejected, (state, action) => {
      state.isDeleteGroupLoading = false;
      state.isDeleteGroupSuccess = false;
      state.isDeleteGroupError = true;
      state.isDeleteGroupErrorMessage = action.payload;
    });
    builder.addCase(getChatDepartments.pending, (state) => {
      state.isDepartmentsLoading = true;
    });
    builder.addCase(getChatDepartments.fulfilled, (state, action) => {
      state.isDepartmentsLoading = false;
      state.isDepartmentsSuccess = true;
      state.departments = action.payload;
    });
    builder.addCase(getChatDepartments.rejected, (state, action) => {
      state.isDepartmentsError = false;
      state.isDepartmentsErrorMessage = action.payload;
    });
    builder.addCase(getGroups.pending, (state) => {
      state.isGroupLoading = true;
    });
    builder.addCase(getGroups.fulfilled, (state, action) => {
      state.isGroupLoading = false;
      state.groups = action.payload;
    });
    builder.addCase(getGroups.rejected, (state, action) => {
      state.isGroupLoading = false;
      state.groups = [];
      state.isGroupError = true;
      state.isGroupErrorMessage = action.payload;
    });

    builder.addCase(getGroupByIdAction.pending, (state) => {
      state.isGroupByIdLoading = true;
    });
    builder.addCase(getGroupByIdAction.fulfilled, (state, action) => {
      state.isGroupByIdLoading = false;
      state.isGroupByIdSuccess = true;
      state.singleGroup = action.payload;
    });
    builder.addCase(getGroupByIdAction.rejected, (state, action) => {
      state.isGroupByIdLoading = false;
      state.singleGroup = {};
      state.isGroupByIdSuccess = false;
      state.isGroupByIdError = true;
      state.isGroupByIdErrorMessage = action.payload;
    });

    builder.addCase(updateGroupStatus.pending, (state) => {
      state.isGroupStatusUpdating = true;
    });
    builder.addCase(updateGroupStatus.fulfilled, (state, action) => {
      state.isGroupStatusUpdating = false;
      state.isGroupStatusUpdating = false;
      state.chatGroups.data = state.chatGroups?.data?.map((obj) => {
        if (obj.id === action.payload.id) {
          return { ...obj, isActive: action.payload.isActive };
        }
        return obj;
      });
    });
    builder.addCase(updateGroupStatus.rejected, (state) => {
      state.isGroupStatusUpdating = false;
      state.isGroupStatusError = true;
    });

    builder.addCase(updateGroup.pending, (state) => {
      state.isGroupUpdating = true;
    });
    builder.addCase(updateGroup.fulfilled, (state) => {
      state.isGroupUpdating = false;
      state.isGroupUpdateSuccess = true;
    });
    builder.addCase(updateGroup.rejected, (state) => {
      state.isGroupUpdating = false;
      state.isGroupUpdateError = true;
      state.isGroupUpdateSuccess = false;
    });
  },
});

export default chatGroupSlice.reducer;
