import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Members from "./members.service";
import Toaster from "../../../components/Toaster";

export const departmentMembers = createAsyncThunk(
  "members",
  async ({ roleType, isOnline }, thunkAPI) => {
    try {
      let response;
      if (isOnline !== undefined) {
        response = await Members.getMembers(roleType, isOnline);
      } else {
        response = await Members.getMembers(roleType);
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Toaster({ type: "error", message });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState = {
  members: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  isErrorMessage: null,
};

export const membersSlice = createSlice({
  name: "members",
  initialState,
  reducers: {
    reset() {},
  },
  extraReducers: (builder) => {
    builder.addCase(departmentMembers.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(departmentMembers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.members = action.payload;
    });

    builder.addCase(departmentMembers.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isErrorMessage = action.payload;
      state.members = [];
    });
  },
});

export default membersSlice.reducer;
