// eslint-disable-next-line import/prefer-default-export
export const ChatStatusEnum = ["OPEN", "IN_PROGRESS", "CLOSE", "ESCALATED"];
export const TicketStatus = [
  {
    label: "OPEN",
    value: 0,
  },
  {
    label: "IN PROGRESS",
    value: 1,
  },
  {
    label: "CLOSE",
    value: 2,
  },
  {
    label: "ESCALATED",
    value: 3,
  },
];
