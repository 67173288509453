import axios from "axios";
import Api from "../../../utils/api";

async function getAllChat() {
  const response = await Api.get("/chat");
  return response.data;
}

async function getChatList(params) {
  const response = await Api.get(
    `/chat/self/?start_date=${params.startDate}&end_date=${params.endDate}`
  );
  return response.data;
}

async function getChat(id) {
  const response = await Api.get(`/chat/${id}`);
  return response.data;
}

async function getChatTickets(params) {
  const response = await Api.get(
    `/chat/tickets/?page=${params?.page}&item=${params?.item}&search=${params?.username}&start_date=${params?.startDate}&end_date=${params?.endDate}&status=${params?.status}`
  );
  return response?.data;
}

async function createChat(payload) {
  const response = await Api.post("/chat", payload);
  return response;
}

async function filterChat(params) {
  const response = await Api.post(
    `chat/filter-chat?start_date=${params.startDate}&end_date=${params.endDate}&status=${params.status}`
  );
  return response.data;
}

async function fileUpload(data) {
  const user = localStorage.getItem("uml_user");
  const auth = user ? JSON.parse(user) : null;
  const response = await axios.post(
    `${process.env.REACT_APP_UPLOAD_URL}/files`,
    data,
    {
      headers: {
        Authorization: `Bearer ${auth.token}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
}

async function getFileByPathname(pathName) {
  const user = localStorage.getItem("uml_user");
  const auth = user ? JSON.parse(user) : null;
  const response = await axios.get(
    `${process.env.REACT_APP_UPLOAD_URL}/files-exports?filePath=${pathName}`,
    {
      headers: {
        Authorization: `Bearer ${auth.token}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
}

async function destroyChat(id) {
  const response = await Api.delete(`/chat${id}`);
  return response.data;
}

async function updateChatStatus(id, body) {
  const data = JSON.stringify(body);

  const response = await Api.put(`/chat/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
}

const Chat = {
  getChatList,
  getChat,
  createChat,
  destroyChat,
  updateChatStatus,
  filterChat,
  getAllChat,
  getChatTickets,
  fileUpload,
  getFileByPathname,
};
export default Chat;
