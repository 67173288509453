import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/auth/auth.slice";
import membersReducer from "./features/members/members.slice";
import chatReducer from "./features/chat/chat.slice";
import chatGroupReducer from "./features/chat-groups/chatGroup.slice";
import conversationReducer from "./features/conversations/conversation.slice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    chat: chatReducer,
    chatGroups: chatGroupReducer,
    conversation: conversationReducer,
    members: membersReducer,
  },
});

export default store;
