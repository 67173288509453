import Api from "../../../utils/api";

/*
 * get all the messages from the server
 */
async function getMessages(chatId) {
  const response = await Api.get(`conversation/${chatId}/messages`);
  return response.data;
}

/*
 * @param {string|integer} chatId, like uuid or 1
 */
async function getMessagesByChatId(chatId) {
  const response = await Api.get(`conversation/${chatId}/messages`);
  return response;
}

/*
 * @param {string|integer} chatId, like uuid or 1
 * @param {string} title, "New Title"
 */
async function updateTitle(chatId, title) {
  const response = await Api.post(`/conversation/${chatId}`, { title });
  return response;
}

/*
 * @param {string|integer} chatId, like uuid or 1
 * @param {object} payload, {"message":"Hi from dev ?"}
 */
async function sendMessage(chatId, payload) {
  const response = await Api.post(`conversation/${chatId}/message`, payload);
  return response;
}

/*
 * @param {string|integer} messageId, like uuid or 1
 *read messages thread(1)
 */
async function readMessage(chatId) {
  const response = await Api.put(`conversation/${chatId}/read-message`);
  return response;
}

/*
 * @param {string|integer} messageId, like uuid or 1
 *soft-delete message from the chat
 */
async function deleteMessage(messageId) {
  const response = await Api.delete(`conversation/message/${messageId}`);
  return response;
}

async function addCallingUsers(data) {
  const response = await Api.post(`chat/add-calling-users`, data);
  return response;
}

async function removeCallingUsers(data) {
  const response = await Api.post(`chat/remove-calling-users`, data);
  return response;
}

async function updateCallingStatus(data) {
  const response = await Api.post(`chat/calling-status`, data);
  return response;
}

const Conversations = {
  getMessages,
  getMessagesByChatId,
  updateTitle,
  sendMessage,
  readMessage,
  deleteMessage,
  addCallingUsers,
  removeCallingUsers,
  updateCallingStatus,
};

export default Conversations;
