const PATH = {
  NOPAGE: "*",

  //  AUTH PATHS
  LOGIN: "/",
  SIGN_UP: "/signup",
  FORGET_PASSWORD: "/forgetpassword",
  HOME: "/chat",

  //  CLIENT PATHS
  CLIENT_DASHBOARD: "/client/dashboard",
  TICKET: "/tickets",
  CHAT: "/chat",
  CHAT_ID: "/chat/:chatId",
  AUDIO_CALL: "/audio-call",
  GROUP: "/business",
  ACCOUNT_SETTINGS: "/accountsettings",
  LIVE_CHAT: "/start-chat",
};

export default PATH;
