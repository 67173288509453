import Toaster from "../../../components/Toaster";
import Api from "../../../utils/api";

// eslint-disable-next-line consistent-return
export async function saveFmcToken(payload) {
  try {
    const response = await Api.post(`fmc/fmc-token`, payload);
    localStorage.setItem("fmc-token", response.data.fmcToken);
    return response;
  } catch (error) {
    Toaster({ type: "error", message: error.message });
  }
}
export async function getFmcToken(userId) {
  const response = await Api.put(`fmc/fmc-tokens/${userId}`);
  return response;
}
// eslint-disable-next-line consistent-return
export async function removeFmcToken(token) {
  try {
    const response = await Api.post(`fmc/remove/fmc-token`, {
      fmcToken: token,
    });
    return response;
  } catch (error) {
    console.log(error?.message);
    // Toaster({ type: "error", message: error.message });
  }
}
