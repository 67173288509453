/* eslint-disable no-console */
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from "firebase/messaging";
import { saveFmcToken } from "../app/features/fmc-token/fmc.service";

const firebaseConfig = {
  apiKey: "AIzaSyDQrXLO7ajZELqydDp2y9HyqVD4GQUN-wM",
  authDomain: "uml-support-app.firebaseapp.com",
  projectId: "uml-support-app",
  storageBucket: "uml-support-app.appspot.com",
  messagingSenderId: "241819638225",
  appId: "1:241819638225:web:ad891b7065df4613d3a8f0",
  measurementId: "G-R5JWYXWT6P",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const initializeFirebaseMessaging = async (userId) => {
  if (!isSupported()) {
    console.log("Firebase Messaging is not supported in this browser.");
    return;
  }

  try {
    const permissionGranted = await Notification.requestPermission();
    if (permissionGranted === "granted") {
      const token = await getToken(messaging);
      if (token) {
        await saveFmcToken({ userId, fmcToken: token });
      }
    } else {
      console.log("Notification permission denied.");
    }
  } catch (error) {
    console.error("Error while requesting permission:", error);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
