import { ConfigProvider, notification } from "antd";
import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
// import RouterConfig from "./Navigation/RouterConfig";
import FullScreenLoader from "./components/Loader/FullScreenLoader";
import { AppProvider } from "./context/appContext";
import AllRoutes from "./Navigation/Routes";
import { onMessageListener } from "./utils/firebase";

function App() {
  const [api, contextHolder] = notification.useNotification();

  onMessageListener()
    .then((payload) => {
      api.info({
        message: payload.notification.title,
        description: payload.notification.body,
      });
    })
    .catch((err) => console.log("fmc-notification-failed: ", err));
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#00478d",
          },
        }}
      >
        <AppProvider>
          <BrowserRouter>
            {contextHolder}
            <AllRoutes />
          </BrowserRouter>
        </AppProvider>
      </ConfigProvider>
    </Suspense>
  );
}

export default App;
