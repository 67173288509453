/* eslint-disable */
import { Button, Col, Form, Modal, Row, Select, Tooltip } from "antd";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import {
  IoIosArrowDown,
  IoMdClose,
  IoMdInformationCircleOutline,
} from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  addMember,
  getChatDepartments,
  getGroupByIdAction,
  removeMember,
} from "../../../../app/features/chat-groups/chatGroup.slice";
import { chatList } from "../../../../app/features/chat/chat.slice";
import { departmentMembers } from "../../../../app/features/members/members.slice";
import { useChatContext } from "../../../../context/chatContext";
import useAuth, { PermissionEnum } from "../../../../hooks/useAuth";
import Toaster from "../../../Toaster";

function AddMember(props) {
  const { userCan } = useAuth();
  const [form] = Form.useForm();
  const { selectedChat } = useChatContext();
  const { modalOpen, setModalOpen, groupId } = props;
  const [groupDataList, setGroupDataList] = useState([]);
  const [memberName, setMemberName] = useState(null);
  const [membersList, setMembersList] = useState(null);
  const [currentMembers, setCurrentMembers] = useState([]);
  const [department, setDepartment] = useState(null);
  const dispatch = useDispatch();

  const { departments, singleGroup } = useSelector((state) => state.chatGroups);
  const { members } = useSelector((state) => state.members);

  const onFinish = (values) => {
    if (groupDataList.length < 1) {
      Toaster({
        type: "warning",
        message: "You not add a member!",
      });
      return;
    }

    const payload = {
      chatId: selectedChat?.id,
      userIds: groupDataList
        ? groupDataList?.map((item) => item?.memberName?.value)
        : [],
    };

    if (groupId) {
      dispatch(addMember({ onSuccess: onSuccess, groupId, payload }));
    }
    setMemberName(null);
    setDepartment(null);
    setGroupDataList([]);
    setModalOpen(false);
  };

  function onSuccess() {
    dispatch(chatList({ startDate: "", endDate: "" }));
  }

  const newGroupData = { memberName, department };

  const add = () => {
    const userExists = currentMembers?.find(
      (user) => user.id === newGroupData.memberName.value
    );

    if (userExists) {
      Toaster({
        type: "warning",
        message: "You cannot add same member again!",
      });
      return;
    }

    setGroupDataList((prevList) => [...prevList, newGroupData]);
    form.setFieldsValue({ department: undefined, memberName: undefined });
    form.resetFields();
  };

  const remove = (index) => {
    setGroupDataList((prevList) => {
      const updatedList = [...prevList];
      updatedList.splice(index, 1);
      return updatedList;
    });
  };

  const removeMemberChat = (user) => {
    Swal.fire({
      title: "Are you sure?",
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#40BEEE",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          removeMember({
            groupId,
            payload: { userIds: [user?.id] },
          })
        );

        setCurrentMembers((members) => members.filter((m) => m != user));
      }
    });
  };

  useEffect(() => {
    if (!modalOpen) return;

    dispatch(getChatDepartments());
    if (groupId) {
      dispatch(getGroupByIdAction({ groupId }));
    }
  }, [modalOpen]);

  useEffect(() => {
    if (department)
      dispatch(departmentMembers({ roleType: department?.value }));
  }, [department]);

  const options =
    departments?.map((item) => {
      return { id: item.id, label: item.name, value: item.id };
    }) || [];

  useEffect(() => {
    if (!members || !singleGroup) return;

    setMembersList(members);
  }, [members]);

  useEffect(() => {
    form.resetFields();
  }, []);

  useEffect(() => {
    if (groupId && singleGroup) {
      setCurrentMembers(singleGroup.users);
    }
  }, [groupId, singleGroup]);

  return (
    <Modal
      className="add-group-modal"
      title={<div className="fs-lg fw-black">Members</div>}
      centered
      open={modalOpen}
      closable={false}
      footer={null}
      destroyOnClose
    >
      {groupId && (
        <>
          <h5 style={{ fontWeight: "bold" }}>Current Members Chat</h5>

          <Row>
            {currentMembers?.map((user) => {
              return (
                <Col span={12} key={user.id}>
                  {user.fullName}
                  {userCan(PermissionEnum.REMOVE_MEMBERS) && (
                    <>
                      <Tooltip
                        title={user.roles?.map((role) => role.name).join(", ")}
                      >
                        <IoMdInformationCircleOutline
                          size={12}
                          style={{ marginLeft: "4px" }}
                        />
                      </Tooltip>
                      <Tooltip title="Remove member">
                        <IoMdClose
                          size={12}
                          color="#FF0000"
                          style={{ marginLeft: "4px", cursor: "pointer" }}
                          onClick={() => removeMemberChat(user)}
                        />
                      </Tooltip>
                    </>
                  )}
                </Col>
              );
            })}
          </Row>

          <br />
        </>
      )}

      {userCan(PermissionEnum.ADD_MEMBERS) && (
        <Form
          name="dynamic_form_nest_item"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          requiredMark={false}
          initialValues={{ department, memberName }}
        >
          <Row className="d-flex align-items-center mb-4 parent-div">
            <Form.Item
              label="Department "
              className="m-0 item"
              name="department"
              rules={[{ required: true, message: "Select department" }]}
            >
              <Select
                className="w-100"
                showSearch
                allowClear
                suffixIcon={<IoIosArrowDown className="text-black" size={18} />}
                placeholder="Select department"
                options={options}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(value, label) =>
                  setDepartment({ value, label: label?.label })
                }
              />
            </Form.Item>
            <Form.Item
              label="Member"
              className="m-0 item"
              name="memberName"
              rules={[{ required: true, message: "Missing member name" }]}
            >
              <Select
                className="w-100"
                allowClear
                showSearch
                suffixIcon={<IoIosArrowDown className="text-black" size={18} />}
                placeholder="Choose member"
                options={
                  membersList?.map((item) => {
                    return {
                      id: item.id,
                      label: item.fullName,
                      value: item.id,
                    };
                  }) || []
                }
                onChange={(value, label) =>
                  setMemberName({ value, label: label?.label })
                }
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
            <div
              className="add-icon-bg cursor-pointer"
              style={{ marginTop: "28px" }}
              role="presentation"
              onClick={() => add()}
            >
              <AiOutlinePlus className="add-icon" size={18} />
            </div>
            <Col xs={24} className="mt-4">
              {groupDataList.length !== 0 && (
                <table className="w-100 group-data-table mb-3">
                  <thead>
                    <tr>
                      <th>Department</th>
                      <th>Member</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {groupDataList.map((item, index) => (
                      <tr key={index}>
                        <td>{item?.department?.label}</td>
                        <td>{item?.memberName?.label}</td>
                        <td>
                          <IoMdClose
                            className="cursor-pointer text-danger"
                            size={18}
                            onClick={() => remove(item.id)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </Col>
          </Row>

          <>
            <Form.Item className="m-0">
              <Row className="d-flex justify-content-end" gutter={12}>
                <Col flex="none">
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Col>
                <Col flex="none">
                  <Button onClick={() => setModalOpen(false)}>Cancel</Button>
                </Col>
              </Row>
            </Form.Item>
          </>
        </Form>
      )}

      {!userCan(PermissionEnum.ADD_MEMBERS) && (
        <Row className="d-flex justify-content-end mt-4" gutter={12}>
          <Col flex="none">
            <Button onClick={() => setModalOpen(false)}>Close</Button>
          </Col>
        </Row>
      )}
    </Modal>
  );
}

export default AddMember;
AddMember.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
};
