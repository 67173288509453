import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { logout } from "../../app/features/auth/auth.slice";
import { ChatProvider } from "../../context/chatContext";
import { SocketProvider } from "../../context/socketContext";
import Api from "../../utils/api";
import { initializeFirebaseMessaging } from "../../utils/firebase";

export default function PrivateRoutes() {
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();

  function setFmc() {
    if (user) {
      initializeFirebaseMessaging(user.user?.id);
    }
  }

  const dispatch = useDispatch();
  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [user]);

  useEffect(() => {
    setFmc();
  }, []);

  useEffect(() => {
    Api.interceptors.response.use(
      (response) => {
        if (response.status === 401 || response.status === 403) {
          window.localStorage.removeItem("uml_user");
          navigate("/");
        } else {
          return response;
        }
        return response;
      },
      (error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          dispatch(logout());
        }
        return error;
      }
    );
    return () => {};
  }, []);

  return user ? (
    <SocketProvider>
      <ChatProvider>
        <Outlet />
      </ChatProvider>
    </SocketProvider>
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
}
