/* eslint-disable */
import { Dropdown, FloatButton } from "antd";
import { useEffect, useState } from "react";
import { RiChatNewLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import IMAGES from "../../../../assets/images";
import useAuth, { PermissionEnum } from "../../../../hooks/useAuth";
import PATH from "../../../../Navigation/Path";

function OpenChatButton({ float }) {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const { userCan } = useAuth();

  const handleIndividualChat = () => {
    navigate(PATH.LIVE_CHAT, { state: { chatType: "Individual" } });
  };

  const handleBusinessChat = () => {
    navigate(PATH.LIVE_CHAT, { state: { chatType: "Group" } });
  };

  const ITEM_INDIVIDUAL = {
    label: "Individual",
    key: "individual",
    onClick: () => {
      handleIndividualChat();
    },
  };

  // const ITEM_GROUP = {
  //   label: "Group",
  //   key: "group",
  //   onClick: () => {
  //     handleBusinessChat();
  //   },
  // };

  useEffect(() => {
    const items = [];
    if (userCan(PermissionEnum.OPEN_INDIVIDUAL_CHATS)) {
      items.push(ITEM_INDIVIDUAL);
    }
    // if (userCan(PermissionEnum.OPEN_GROUP_CHATS)) {
    //   items.push(ITEM_GROUP);
    // }

    setItems(items);
  }, []);

  if (items.length < 1) {
    return <></>;
  }

  return (
    <>
      {!float && (
        <a
          className="cursor-pointer mr-3 hide-on-mobile"
          onClick={ITEM_INDIVIDUAL.onClick}
        >
          <div className="support-icon-bg">
            <RiChatNewLine size={18} color="primary" />
          </div>
        </a>
      )}

      {float && (
        <FloatButton
          className="d-sm-none support-icon-bg"
          color="primary"
          style={{
            width: "50px",
            height: "50px",
          }}
          icon={
            <RiChatNewLine
              size={18}
              color="primary"
              style={{ color: "#00478d" }}
            />
          }
          onClick={ITEM_INDIVIDUAL.onClick}
        />
      )}
    </>
  );

  return (
    <Dropdown
      className="cursor-pointer mr-3"
      menu={{
        items,
      }}
      trigger={["click"]}
      placement="bottomLeft"
    >
      <div className="support-icon-bg">
        <img className="support-icon" src={IMAGES.SUPPORT_ICON} alt="user" />
      </div>
    </Dropdown>
  );
}

export default OpenChatButton;
