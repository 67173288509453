/* eslint-disable */
import { Col, Dropdown, Layout, Typography } from "antd";
import { useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { userOnlineStatus } from "../../../app/features/auth/auth.service";
import { logout } from "../../../app/features/auth/auth.slice";
import { removeFmcToken } from "../../../app/features/fmc-token/fmc.service";
import IMAGES from "../../../assets/images";
import { useApp } from "../../../context/appContext";

import useAuth from "../../../hooks/useAuth";
import OpenChatButton from "./components/OpenChatButton";
import SidebarMenu from "./components/SidebarMenu";

const { Header, Content } = Layout;
const { Text } = Typography;

function MainLayout() {
  const { user } = useAuth();
  const { hasMenu } = useApp();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleLogout = async () => {
    const token = localStorage.getItem("fmc-token");

    await removeFmcToken(token).then((_res) =>
      localStorage.removeItem("fmc-token")
    );
    await userOnlineStatus({
      isOnline: false,
    });
    dispatch(logout());
  };

  const items = [
    {
      label: "Logout",
      key: "logout",
      onClick: () => {
        handleLogout();
      },
    },
  ];

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update the windowWidth state whenever the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="private-layout">
      <Layout>
        <SidebarMenu />
        <Layout>
          <Header
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              zIndex: 8,
              display: "flex",
              alignItems: "center",
              padding: 0,
              justifyContent: "space-between",
            }}
          >
            <div className="align-items-center justify-content-between d-md-flex">
              <div className="d-flex align-items-center justify-content-center">
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/chat");
                  }}
                >
                  <img src={IMAGES.UML_LOGO} alt="logo" className="uml-logo" />
                  <Text
                    strong
                    className="d-none d-md-inline-block"
                    style={{ fontSize: "16px" }}
                  >
                    Soulmi Health Support
                  </Text>
                </a>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <OpenChatButton />
              <img
                className="user-avatar"
                src={user?.avatar || IMAGES.NO_USER_GREY}
                alt="user"
              />
              <Col className="ml-3 d-none d-lg-block">
                <Text>{user?.fullName}</Text>
              </Col>

              <Dropdown
                className="cursor-pointer"
                menu={{
                  items,
                }}
                trigger={["click"]}
                placement="bottomLeft"
              >
                <IoIosArrowDown size="20" className="ml-3" />
              </Dropdown>
            </div>
          </Header>
          <Content>
            <div
              className="layout-child"
              style={{ marginLeft: hasMenu ? "260px" : "0px" }}
            >
              <Outlet />
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default MainLayout;
