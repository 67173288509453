/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import React, { createContext, useContext, useState } from "react";

const AppContext = createContext();

// eslint-disable-next-line react/prop-types
export function AppProvider({ children }) {
  const [hasMenu, setHasMenu] = useState(false);

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <AppContext.Provider
      value={{
        hasMenu,
        setHasMenu,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export const useApp = () => useContext(AppContext);

export default AppContext;
