/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { Button, Modal } from "antd";
import React, { createContext, useEffect, useState } from "react";
import { IoCallSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import io from "socket.io-client";
// import useSound from "use-sound";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDispatch, useSelector } from "react-redux";
import Sound from "react-sound";

// eslint-disable-next-line import/no-cycle
import { userOnlineStatus } from "../app/features/auth/auth.service";
import { ShowRingingModal } from "../app/features/chat/chat.slice";
import { UpdateCallingStatus } from "../app/features/conversations/conversation.slice";
import RingingModal from "../components/pages/Chat/components/RingingModal";
import Toaster from "../components/Toaster";
import useAuth from "../hooks/useAuth";

const SocketContext = createContext();

// eslint-disable-next-line react/prop-types
export function SocketProvider({ children }) {
  const [socket, setSocket] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [incomingCall, setIncomingCall] = useState(null);
  const chats = useSelector((state) => state.chat);
  const { user, isAE } = useAuth();

  const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

  const navigate = useNavigate();
  const [showCallModal, setShowCallModal] = useState(false);

  const dispatch = useDispatch();
  const toggleSound = (sound) => {
    setIsPlaying(sound);
  };

  useEffect(() => {
    if (!user) return;

    if (!isAE) {
      userOnlineStatus({
        isOnline: true,
      });
    }

    const newSocket = io(SOCKET_URL);
    setSocket(newSocket);
    newSocket?.emit("join", { user });

    const roomId = user.id;
    const joinRoomData = {
      username: user?.fullName,
      room: roomId,
    };

    newSocket?.emit("joinRoom", joinRoomData);

    console.log(`Connected to chat`, joinRoomData);

    newSocket.on(
      "incomingCall",
      ({
        aeId,
        roomMembers,
        name,
        avatar,
        callerId,
        sessionId,
        token,
        chatId,
        groupId,
      }) => {
        setIncomingCall({
          isReceivingCall: true,
          aeId,
          roomMembers,
          name,
          avatar,
          callerId,
          sessionId,
          token,
          chatId,
          groupId,
        });

        toggleSound(true);
        setShowCallModal(true);
      }
    );

    newSocket.on("cancelCallNotification", ({ message, name, avatar }) => {
      setShowCallModal(false);
      toggleSound(false);
    });

    newSocket.on(
      "callNotification",
      ({ sessionId, token, callerId, name, avatar }) => {
        dispatch(ShowRingingModal({ showRingingModal: false }));
      }
    );

    newSocket.on("disconnectCallNotification", (data) => {
      if (data?.chatId) {
        dispatch(
          UpdateCallingStatus({
            data: {
              chatId: data?.chatId?.toString(),
              isActive: false,
            },
            moveToNext: (response) => {
              Toaster({ type: "error", message: response?.message });
              navigate("/chat");
            },
          })
        );
      } else {
        Toaster({ type: "error", message: "Chat Id is required to cancel!" });
      }
    });

    newSocket.on("answerCallNotification", (data) => {
      toggleSound(false);
      dispatch(ShowRingingModal({ showRingingModal: false }));
      dispatch(
        UpdateCallingStatus({
          data: {
            chatId: data.chatId.toString(),
            isActive: true,
          },
          moveToNext: () => {
            navigate(
              {
                pathname: "/audio-call",
                search: `?chatId=${data?.chatId}&groupId=${data?.groupId}`,
              },
              {
                state: {
                  callingDetails: data,
                },
              }
            );
          },
        })
      );
    });

    // eslint-disable-next-line consistent-return
    return () => {
      newSocket?.emit("leaveRoom");
      newSocket.disconnect();
    };
  }, []);

  const rejectCall = () => {
    setShowCallModal(false);
    toggleSound(false);

    socket.emit("rejectCall", {
      name: user?.fullName,
      callerId: incomingCall?.callerId,
      avatar: user?.avatar,
      message: "Call ended",
    });
  };

  const attendCall = () => {
    toggleSound(false);
    setShowCallModal(false);
    dispatch(ShowRingingModal({ showRingingModal: false }));
    socket.emit("answerCall", {
      aeId: incomingCall?.aeId,
      roomMembers: incomingCall?.roomMembers,
      name: user?.fullName,
      avatar: user?.avatar,
      callerId: incomingCall?.callerId,
      message: "Call Started",
      token: incomingCall?.token,
      sessionId: incomingCall?.sessionId,
      chatId: incomingCall?.chatId,
      groupId: incomingCall?.groupId,
    });

    navigate(
      {
        pathname: "/audio-call",
        search: `?chatId=${incomingCall?.chatId}&groupId=${incomingCall?.groupId}`,
      },
      {
        state: {
          callingDetails: incomingCall,
        },
      }
    );
  };

  const cancelCall = (callDetails) => {
    socket?.emit("cancelCall", {
      aeId: callDetails.aeId,
      roomMembers: callDetails.roomMembers,
      name: callDetails.name,
      avatar: callDetails.avatar,
      message: `Call cancelled from ${callDetails.name}`,
    });
  };

  // Function to start or stop sound playback

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <SocketContext.Provider
      value={{
        socket,
        incomingCall,
        setIncomingCall,
      }}
    >
      {children}

      <Modal
        className="call-modal"
        // title="Basic Modal"
        open={showCallModal}
        header="null"
        footer={null}
        width={300}
        height={200}
        // onOk={handleOk}
        // onCancel={handleCancel}
      >
        <Button className="visibility-none">
          <p className="">Hover over me!</p>
        </Button>
        <h1 className="caller-name">{incomingCall?.name}</h1>
        <div className="mb-4 d-flex  text-center align-items-center justify-content-center">
          <h2 className="mb-0">Calling </h2>
          <BeatLoader />
        </div>
        <div className="d-flex justify-content-between">
          <div
            className="call-disconnect d-flex align-items-center p-3 cursor-pointer"
            onClick={() => rejectCall(false)}
            onKeyDown={() => rejectCall(false)}
            role="button"
            tabIndex="0"
          >
            <IoCallSharp />
          </div>
          <div
            className="call-accept d-flex align-items-center p-3 cursor-pointer"
            onClick={() => attendCall()}
            onKeyDown={() => attendCall()}
            role="button"
            tabIndex="0"
          >
            <IoCallSharp />
          </div>
        </div>
      </Modal>
      {chats && (
        <RingingModal
          show={chats?.showRingingModal}
          callDetails={chats?.videoDetails}
          toggleSound={toggleSound}
          cancelCall={cancelCall}
        />
      )}
      <Sound
        url="https://www.zapsplat.com/wp-content/uploads/2015/sound-effects-84577/zapsplat_multimedia_ringtone_mallets_marimba_smartphone_001_84890.mp3"
        playStatus={isPlaying ? Sound.status.PLAYING : Sound.status.PAUSED}
        playFromPosition={0} // Start playing from 300 milliseconds
        // onLoading={this.handleSongLoading}
        // onPlaying={this.handleSongPlaying}
        // onFinishedPlaying={this.handleSongFinishedPlaying}
        volume={100} // Adjust the volume as needed (0-100)
        loop // Set to true for continuous looping
      />
    </SocketContext.Provider>
  );
}

export default SocketContext;
