/* eslint-disable import/no-named-as-default */
/* eslint-disable no-unused-vars */
/* eslint-disable */
import { useEffect, useState } from "react";

import { DatePicker, Select, Tooltip, Typography } from "antd";
import { FaFilter } from "react-icons/fa";
import { RxDotFilled } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { chatList } from "../../../../app/features/chat/chat.slice";
import IMAGES from "../../../../assets/images";
import { useChatContext } from "../../../../context/chatContext";
import useAuth from "../../../../hooks/useAuth";
import { replaceTimezoneWithCurrent } from "../../../../utils/helpers";
import BadgeUnreadMessage from "./BadgeUnreadMessage";
import OpenChatButton from "../../../Layout/Private/components/OpenChatButton";

function Avatar({ item, selectedChat }) {
  const [src, setSrc] = useState("");

  useEffect(() => {
    let imageSrc;
    if (item?.groups?.users.length === 1) {
      if (selectedChat?.id === item?.id) {
        imageSrc = IMAGES.NO_USER_WHITE;
      } else {
        imageSrc = IMAGES.NO_USER_GREY;
      }
    } else {
      if (selectedChat?.id === item?.id) {
        imageSrc = IMAGES.GROUP_CHAT_WHITE;
      } else {
        imageSrc = IMAGES.GROUP_CHAT_GREY;
      }
    }
    setSrc(imageSrc);
  }, [item, selectedChat]);

  return (
    <img
      src={src}
      className={`sender-avatar mr-2 ${
        selectedChat?.id === item?.id ? "active" : ""
      }`}
      alt="user"
    />
  );
}

function ToolbarRight() {
  const { user, isAE } = useAuth();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { selectedChat, setSelectedChat, newMessage } = useChatContext();
  const { Text } = Typography;
  const [onlineStatus, setOnlineStatus] = useState("Online");
  const [selectedFilter, setSelectedFilter] = useState(null);
  const { chats } = useSelector((state) => state.chat);
  const { messages } = useSelector((state) => state.conversation);

  const [dates, setDates] = useState({
    startDate: "",
    endDate: "",
  });

  const { RangePicker } = DatePicker;

  useEffect(() => {
    dispatch(chatList(dates));
  }, [messages, dates]);

  useEffect(() => {
    if (!newMessage) return;

    const isNewChat = !chats?.find((chat) => chat.id === newMessage.chatId);
    if (isNewChat) {
      dispatch(chatList(dates));
    }
  }, [newMessage]);

  const handleDate = (_date, dateString) => {
    setDates({
      startDate: dateString[0]
        ? new Date(dateString[0]).toISOString().split("T")[0]
        : "",
      endDate: dateString[1]
        ? new Date(dateString[1]).toISOString().split("T")[0]
        : "",
    });
  };

  // useEffect(() => {
  //   if (!selectedChat) return;

  //   dispatch(getMessagesListByChatId({ chatId: selectedChat?.id }));
  // }, [selectedChat]);

  const options = [
    {
      value: "day",
      label: "Day",
    },
    {
      value: "month",
      label: "Month",
    },
    {
      value: "year",
      label: "Year",
    },
  ];

  const handleThread = (val) => {
    setSelectedChat({
      groupName: val?.groups.groupName,
      id: val?.id,
      groupId: val?.groupId,
      groupMembers: val?.groups?.users,
      title: val?.title,
      currentStatus: val?.currentStatus,
      createdAt: val?.createdAt,
      updatedAt: val?.updatedAt,
      sender: val?.sender,
      isCalling:
        val.activeCallers === null || val.activeCallers.length === 0
          ? val.isCalling === true && false
          : true,
      ticketId: val?.ticketId,
    });

    // if (val.activeCallers === null || val.activeCallers.length === 0) {
    //   if (val.isCalling === true) {
    //     dispatch(
    //       UpdateCallingStatus({
    //         data: {
    //           chatId: val.id.toString(),
    //           isActive: false,
    //         },
    //         moveToNext: () => {},
    //       })
    //     );
    //   }
    // }

    // dispatch(readMessages({ chatId: val?.id }));

    navigate(`/chat/${val?.id}`);
  };

  return (
    <div className={`chat-details ${selectedChat ? "hide-on-mobile" : ""}`}>
      <OpenChatButton float={true} />
      <div className="d-flex justify-content-between align-items-center p-4">
        <div className="d-flex align-items-center">
          <img
            src={user?.avatar || IMAGES.NO_USER_GREY}
            className="user-avatar-2 mr-2"
            alt="user"
          />

          <div>
            <div className="fw-bold fs-sm mb-1">{user?.fullName}</div>
            <div className="status-btn d-flex align-items-center pl-1 pr-2">
              <RxDotFilled size={22} className="text-success" />{" "}
              <p className="fw-light fs-xs m-0 mr-1">{onlineStatus}</p>{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="search-input px-4">
        <Select
          className="w-100"
          showSearch
          allowClear
          suffixIcon={<FaFilter className="text-black" size={18} />}
          placeholder="Filter by"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          onClear={() => setSelectedFilter(null)}
          onSelect={(value) => setSelectedFilter(value)}
          options={options}
        />
        {selectedFilter && (
          <RangePicker
            picker={
              // selectedFilter === "year" &&
              selectedFilter
            }
            onChange={handleDate}
            className="w-100 mt-3"
          />
        )}
      </div>
      <div className="d-flex align-items-center mt-4 px-4">
        <p className="m-0 mr-2 fw-bold fs-sm">Chats</p>
      </div>
      <div className="chat-list pt-3">
        {chats?.map((item) => {
          return (
            <div
              className={`single-chat d-flex justify-content-between align-items-center p-2 cursor-pointer ${
                selectedChat?.id === item?.id ? "active" : ""
              }`}
              key={item?.id}
              role="presentation"
              onClick={() => handleThread(item)}
            >
              <div className="d-flex align-items-center">
                <Avatar item={item} selectedChat={selectedChat} />
                <div className="justify-content-center">
                  <Tooltip
                    title={
                      isAE
                        ? item?.title
                        : item?.groups?.users
                            ?.map((val) => val.fullName)
                            ?.join(", ")
                    }
                    color="#87cb60"
                  >
                    <div className="fw-black fs-xs-sm">
                      <Text
                        style={{
                          width: 150,
                          fontWeight: "bold",
                        }}
                        ellipsis={true}
                        className={`${
                          selectedChat?.id === item?.id
                            ? "text-white"
                            : "text-black"
                        }`}
                      >
                        {isAE ? item?.title : item?.sender?.fullName}
                      </Text>
                    </div>
                  </Tooltip>
                  <Tooltip placement="bottom" title={item?.lastMessage}>
                    <Text
                      style={{ width: 150 }}
                      ellipsis={true}
                      className={
                        selectedChat?.id === item?.id
                          ? "text-white"
                          : "text-black"
                      }
                    >
                      {item?.lastMessage || " "}
                    </Text>
                  </Tooltip>
                </div>
              </div>
              <div className="align-items-vertically-center position-relative">
                <BadgeUnreadMessage chat={item} />
                <p
                  className={`fw-light fs-xs m-0 mr-1 d-lg-block ${
                    selectedChat?.id === item?.id ? "text-white" : ""
                  }`}
                >
                  {replaceTimezoneWithCurrent(item?.createdAt)?.split(",")[0]}
                </p>{" "}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ToolbarRight;
