import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./auth.service";
import Toaster from "../../../components/Toaster";

const user = JSON.parse(localStorage.getItem("uml_user"));

export const login = createAsyncThunk(
  "auth/login",
  async ({ values, onSuccess }, thunkAPI) => {
    try {
      const response = await authService.login(values);
      if (response.status === 200) {
        onSuccess(response.data);
        Toaster({ type: "success", message: "Logged in successfully" });
      }
      return response;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      Toaster({ type: "error", message });

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Registration user
export const register = createAsyncThunk(
  "auth/register",
  async ({ data, callBack }, thunkAPI) => {
    try {
      await authService.register(data);
      callBack();
      return true;
    } catch (error) {
      callBack(error.message || error, false);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async (thunkAPI) => {
  try {
    const response = await authService.logout();
    if (response.status === 200) {
      Toaster({ type: "success", message: "Logged out" });
    }
    return response;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    Toaster({ type: "error", message });

    return thunkAPI.rejectWithValue(message);
  }
});

// export const authSlice = createSlice({
//   name: "auth",
//   initialState,
//   reducers: {
//     logout: (state) => {
//       state.isLoggedIn = false;
//       state.user = null;
//       state.isLoginSuccess = false;
//     },
//     reset: (state) => {
//       state.isLoginLoading = false;
//       state.isLoginSuccess = false;
//       state.isLoginError = false;
//       state.loginMessage = "";
//     },
//     updateUser: (state) => {
//       const updatedUser = JSON.parse(localStorage.getItem("user"));
//       state.user = updatedUser;
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(login.pending, (state) => {
//         state.isLoginLoading = true;
//         state.loginMessage = "";
//       })
//       .addCase(login.fulfilled, (state, action) => {
//         state.isLoginLoading = false;
//         state.isLoginSuccess = true;
//         state.isLoginLoggedIn = true;
//         state.user = action.payload;
//       })
//       .addCase(login.rejected, (state, action) => {
//         state.loginMessage = action.payload;
//         state.isLoginLoading = false;
//         state.isLoginError = true;
//         state.user = null;
//       })
//       .addCase(register.pending, (state) => {
//         state.isRegisterLoading = true;
//         state.registerMessage = "";
//       })
//       .addCase(register.fulfilled, (state) => {
//         state.isRegisterLoading = false;
//         state.isRegisterSuccess = true;
//       })
//       .addCase(register.rejected, (state, action) => {
//         state.registerMessage = action.payload;
//         state.isRegisterLoading = false;
//         state.isRegisterError = true;
//       })
//       .addCase(logout.fulfilled, (state) => {
//         state.user = null;
//       });
//   },
// });

const initialState = {
  user: user || null,
  isLoginLoading: false,
  isLoginError: false,
  isLoginSuccess: false,
  loginErrorMessage: "",

  isRegisterLoading: false,
  isRegisterError: false,
  isRegisterSuccess: false,
  registerErrorMessage: "",

  isLogoutLoading: false,
  isLogoutError: false,
  isLogoutSuccess: false,
  logoutErrorMessage: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoginLoading = false;
      state.isLoginSuccess = false;
      state.isLoginError = false;
      state.loginErrorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoginLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoginLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoginLoading = false;
        state.isLoginSuccess = false;
        state.isLoginError = true;
        state.loginErrorMessage = action.payload;
      })
      .addCase(logout.pending, (state) => {
        state.isLogoutLoading = true;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isLogoutLoading = false;
        state.isLogoutSuccess = true;
        state.user = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLogoutLoading = false;
        state.isLogoutSuccess = false;
        state.isLogoutError = true;
        state.logoutErrorMessage = action.payload;
      });
  },
});

export const { reset } = authSlice.actions;

export default authSlice.reducer;
