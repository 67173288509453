import login from "./login.png";
import notFound from "./notfound.jpg";
import supportIcon from "./supportIcon.png";
import umlLogo from "./logo.png";
import noUserWhite from "./no-user-white.png";
import noUserGrey from "./no-user-grey.png";
import groupChatWhite from "./group-chat-white.png";
import groupChatGrey from "./group-chat-grey.png";

// https://cdn-icons-png.flaticon.com/512/6229/6229493.png
// https://www.flaticon.com/free-icon/group-chat_2352167?term=chat+group&page=1&position=3&origin=search&related_id=2352167

const IMAGES = {
  NOTFOUND: notFound,
  UML_LOGO: umlLogo,
  SUPPORT_ICON: supportIcon,
  LOGIN: login,
  NO_USER_GREY: noUserGrey,
  NO_USER_WHITE: noUserWhite,
  GROUP_CHAT_GREY: groupChatGrey,
  GROUP_CHAT_WHITE: groupChatWhite,
};
export default IMAGES;
