import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useChatContext } from "../../../../context/chatContext";
import useAuth from "../../../../hooks/useAuth";

function BadgeUnreadMessage({ chat }) {
  const { user } = useAuth();
  const { selectedChat } = useChatContext();
  const [totalUnread, setTotalUnread] = useState(null);

  useEffect(() => {
    if (!chat) return;

    if (selectedChat?.id === chat?.id) {
      setTotalUnread(null);
      return;
    }

    let unread = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const conversation of chat.conversations) {
      const readMessage = !!conversation?.readBy?.find(
        (u) => u?.userId === user.id
      );

      // eslint-disable-next-line no-plusplus
      if (!readMessage) unread++;
    }

    setTotalUnread(unread);
  }, [selectedChat, chat]);

  if (!totalUnread || !user) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
    <div
      className="bg-success"
      style={{
        borderRadius: "20px",
        width: "20px",
        height: "20px",
        position: "absolute",
        right: "0px",
        top: "-20px",
        textAlign: "center",
        color: "white",
      }}
    >
      {totalUnread}
    </div>
  );
}

export default BadgeUnreadMessage;

BadgeUnreadMessage.propTypes = {
  chat: PropTypes.object.isRequired,
};
